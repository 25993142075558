import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getTeamStatistics } from 'store/team/teamService';
import { getPlayerStatistics } from 'store/player/playerService';
import { useLexicon } from 'lib/hooks/useTranslates';
import { teamActions, teamSlice } from 'store/team/teamSlice';

const ALL_COMPETITIONS_KEY = 'all competitions'
const PRE_SET_MATCHES = [
  {
    checked: true,
    label: 'none',
    value: 1000,
  }, {
    checked: false,
    label: 'last 3 matches',
    value: 3,
  },
  {
    checked: false,
    label: 'last 5 matches',
    value: 5,
  },
  {
    checked: false,
    label: 'last 10 matches',
    value: 10,
  },
]

export const useMatchesSettings = ({ selectMatchesOptions }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [teamId, setTeamId] = useState(null);
  const [playerId, setPlayerId] = useState(null);
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  const teamStatistic = useSelector((state) => state.team.teamStatistic);
  const matchesTeam = useSelector((state) => state.team.teamStatistic?.matches_list);
  const teamSettings = useSelector((state) => state.team.teamSettings);
  const playerStatistic = useSelector((state) => state.player.playerStatistic);
  const matchesPlayer = useSelector((state) => state.player.playerStatistic?.matches_list);
  // const [startDate, setStartDate] = useState(todayOneYearAgo);
  // const [endDate, setEndDate] = useState(new Date());
  const [seasons, setSeasons] = useState(teamSettings?.seasons ?? []);
  const selectedMatches = useSelector((state) => state.team.selectedMatches);
  const [
    selectedCountMatches,
    setSelectedCountMatches,
  ] = useState(teamSettings?.selectedCountMatches ?? PRE_SET_MATCHES);
  const [competitions, setCompetitions] = useState(teamSettings?.competitions ?? []);
  const [settings, setSettings] = useState(teamSettings?.seasons ?? null)

  const handleChangeSeason = (season) => {
    setSeasons((prev) => prev.map(
      (option) => (option.value === season.value
        ? { ...option, checked: !option.checked }
        : { ...option, checked: false }
      ),
    ))
    const idSeason = season?.value

    if (!idSeason && (teamStatistic?.team?.id || playerId)) return

    if (teamStatistic?.team?.id) {
      dispatch(getTeamStatistics({
        id: teamStatistic?.team?.id,
        year_season_id: idSeason,
      }));
    } else {
      dispatch(getPlayerStatistics({
        id: playerId,
        year_season_id: idSeason,
      }));
    }
    searchParams.set('season', season?.value);
  }

  const l = useLexicon();

  const openSettings = () => {
    setIsOpenSettings((prev) => !prev)
  }

  const handleSaveSettings = (e) => {
    e?.preventDefault()

    setSettings({
      competitions,
      seasons,
      selectedCountMatches,
    })

    dispatch(teamSlice.actions.setTeamSettings({
      competitions,
      seasons,
      selectedCountMatches,
    }))
  }

  const handleSelectedCompetition =
    (selectedOption) => {
      const comps = competitions.map(
        (option) => (
          option.value === selectedOption.value
            ? {
              ...option,
              checked: !option.checked,
            }
            : {
              ...option,
              checked: option.value === ALL_COMPETITIONS_KEY
                ? false
                : !option.checked,
            }
        ),
      )
      setCompetitions(comps)
    }

  const handleResetSettings = () => {
    setSettings(null)
    setCompetitions(
      (prev) => prev.map(
        (comp) => (comp.value === ALL_COMPETITIONS_KEY
          ? { ...comp, checked: true }
          : comp),
      ),
    )
    setSeasons(
      (prev) => prev?.map(
        (comp, i) => (i === 0
          ? { ...comp, checked: true }
          : { ...comp, checked: false }),
      ),
    )
    setSelectedCountMatches(PRE_SET_MATCHES)
    dispatch(teamSlice.actions.setTeamSettings(null))
  }
  useEffect(() => {
    const idPlayerParam = searchParams.get('player');
    const idTeamParam = searchParams.get('team');

    idPlayerParam && setPlayerId(+idPlayerParam);
    idTeamParam && setTeamId(+idTeamParam);

    setSeasons(
      (teamStatistic?.available_seasons ?? playerStatistic?.available_seasons)?.map(
        (season) => ({
          checked: season.id?.toString() === searchParams.get('season'),
          label: season.years,
          value: season.id,
        }
        ),
      ),
    )

    if (seasons?.every(({ checked }) => !checked)) {
      setSeasons((prev) => prev?.map(
        (el, i) => (i === 0 ? { ...el, checked: true } : el),
      ))
    }

    if ((matchesTeam ?? matchesPlayer)?.length) {
      const mapCompetition = new Set(
        (matchesTeam ?? matchesPlayer)?.map((match) => match?.competition),
      )
      const comps = Array.from(mapCompetition).map((comp) => ({
        checked: false,
        label: comp,
        value: comp,
      }))
      setCompetitions([{
        checked: true,
        label: ALL_COMPETITIONS_KEY,
        value: ALL_COMPETITIONS_KEY,
      },
      ...comps,
      ])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, matchesTeam, matchesPlayer])

  const selectOptions = useMemo(() => {
    if (selectMatchesOptions?.length) return selectMatchesOptions

    if (matchesTeam?.length > 0 && teamId) {
      const options = matchesTeam.map((match) => ({
        key: match.id,
        title: `${match.home_team} ${match.score} ${match.away_team} ${match.date?.split('T')[0]}, ${match.competition}`,
        ...match,
      }))
      return options;
    } if (matchesPlayer?.length > 0 && playerId) {
      const options = matchesPlayer.map((match) => ({
        key: match.id,
        title: `${match.home_team} ${match.score} ${match.away_team} ${match.date?.split('T')[0]}, ${match.competition}`,
        ...match,
      }))
      return options;
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchesTeam, matchesPlayer, teamId, playerId]);

  const getFilteredMatches = (matches) => {
    if (!settings) return matches

    const filteredMatches = matches.filter((match) => {
      // Проверка на соответствие дате
      //   const isDateInRange = new Date(match.date) >= new Date(settings?.startDate)
      //     && new Date(match.date) <= new Date(settings?.endDate);
      // Проверка на соответствие соревнованию
      const isCompetitionMatch = (
        match.competition === settings?.competitions?.find(({ checked }) => checked)?.value)
                || settings?.competitions?.find(
                  (comp) => comp.value === ALL_COMPETITIONS_KEY,
                );

      // Возвращаем результат с учетом всех условий
      return isCompetitionMatch
    })

    if (settings?.selectedCountMatches?.find(({ checked }) => checked)?.value) {
      return filteredMatches.slice(0, settings?.selectedCountMatches
        ?.find(({ checked }) => checked)?.value)
    }
    return filteredMatches
  };

  useEffect(() => {
    if (selectedMatches.length >= getFilteredMatches(selectOptions)?.length) {
      selectedMatches?.forEach((id) => {
        dispatch(teamActions.setSelectedMatches({
          checked: !!getFilteredMatches(selectOptions).find((el) => el.id === id),
          id,
        }))
      })
    } else {
      getFilteredMatches(selectOptions)?.forEach((match) => {
        dispatch(teamActions.setSelectedMatches({
          checked: true,
          id: match.id,
        }))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    if (selectedMatches.length) {
      selectedMatches?.forEach((id) => {
        dispatch(teamActions.setSelectedMatches({
          checked: true,
          id,
        }))
      })
    } else {
      selectOptions?.forEach(({ id }) => {
        dispatch(teamActions.setSelectedMatches({
          checked: true,
          id,
        }))
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptions]);

  return {
    competitions,
    handleChangeSeason,
    handleResetSettings,
    handleSaveSettings,
    handleSelectedCompetition,
    isOpenSettings,
    openSettings,
    seasons,
    selectOptions,
    selectedCountMatches,
    setIsOpenSettings,
    setSelectedCountMatches,
    settings,
  }
}

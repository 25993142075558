import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRecords, removeRecords } from 'requests';
import { $api } from '../../config/api';

export const getPlaylist = createAsyncThunk(
  'platform/get_playlist',
  async ({ page = 1, token }, { rejectWithValue }) => {
    try {
      const response = await $api.get(`/users/user_playlists/?page=${page}`, {
        headers: { Authorization: `Token ${token}` },
      });
      const records = await getRecords()

      return {
        ...response.data,
        results: [...response.data.results, ...records],
      };
    } catch (e) {
      return rejectWithValue(`${e.response.data.message}`);
    }
  },
);

export const createPlaylist = createAsyncThunk(
  'platform/create_playlist',
  async ({
    events,
    title,
    token,
  }, { rejectWithValue }) => {
    try {
      const body = {
        events: events?.length > 0 ? events : [],
        title: title || 'untitled',
      };
      const response = await $api.post(
        '/users/create_playlist/',
        { ...body },
        { headers: { Authorization: `Token ${token}` } },
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(`${e.response.data.message}`);
    }
  },
);

export const deletePlaylist = createAsyncThunk(
  'platform/delete_playlist',
  async ({
    id,
    isRecord,
    token,
  }, { rejectWithValue }) => {
    try {
      if (isRecord) {
        const response = await removeRecords()

        return response
      }
      const response = await $api.delete(`/users/user_playlists/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      });
      return response.data;
    } catch (e) {
      return rejectWithValue(`${e.response.data.message}`);
    }
  },
);

export const editPlaylistEvents = createAsyncThunk(
  'platform/edit_playlist_events',
  async ({
    events,
    id,
    token,
  }, { rejectWithValue }) => {
    try {
      const body = { events: events?.length > 0 ? events : [] };
      const response = await $api.post(
        `/users/edit_events_playlist/${id}`,
        { ...body },
        { headers: { Authorization: `Token ${token}` } },
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(`${e.response.data.message}`);
    }
  },
);

export const deletePlaylistEvents = createAsyncThunk(
  'platform/delete_playlist_events',
  async ({
    events,
    id,
    token,
  }, { rejectWithValue }) => {
    try {
      const body = { events: events?.length > 0 ? events : [] };
      const response = await $api.delete(
        `/users/edit_events_playlist/${id}`,
        { ...body },
        { headers: { Authorization: `Token ${token}` } },
      );
      return response.data;
    } catch (e) {
      return rejectWithValue(`${e.response.data.message}`);
    }
  },
);

export const SystemSettingsIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    data-name='Layer 1'
    viewBox='0 0 24 24'
  >
    <path d='M21.3 13.88l-.45-.26a8.988 8.988 0 000-3.26l.45-.26A3.014 3.014 0 0022.41 6c-.4-.69-1.05-1.19-1.82-1.4-.77-.21-1.58-.1-2.28.3l-.45.26A8.981 8.981 0 0015 3.51v-.52c0-1.65-1.35-3-3-3s-3 1.35-3 3v.52c-1.05.37-2.02.93-2.86 1.65l-.45-.26c-.69-.4-1.5-.5-2.28-.3-.77.21-1.42.71-1.82 1.4a3.016 3.016 0 001.1 4.1l.45.26a8.988 8.988 0 000 3.26l-.45.26a3.014 3.014 0 00-1.11 4.1c.4.69 1.05 1.19 1.82 1.4.77.21 1.58.1 2.28-.3l.45-.26c.84.72 1.81 1.28 2.86 1.65v.52c0 1.65 1.35 3 3 3s3-1.35 3-3v-.52c1.05-.37 2.02-.93 2.86-1.65l.45.26c.69.4 1.5.51 2.28.3.77-.21 1.42-.71 1.82-1.4.82-1.43.33-3.27-1.1-4.1zm-2.56-3.74c.17.62.26 1.25.26 1.86s-.09 1.23-.26 1.86c-.12.44.07.9.47 1.13l1.09.63c.48.28.64.89.37 1.37-.13.23-.35.4-.61.47-.26.07-.53.04-.76-.1l-1.09-.63a.986.986 0 00-1.21.17c-.89.91-2.01 1.56-3.25 1.88-.44.11-.75.51-.75.97v1.26c0 .55-.45 1-1 1s-1-.45-1-1v-1.26c0-.46-.31-.85-.75-.97A6.979 6.979 0 017 16.9c-.19-.2-.45-.3-.72-.3-.17 0-.34.04-.5.13l-1.09.63c-.23.13-.5.17-.76.1s-.47-.24-.61-.47a1.01 1.01 0 01.37-1.37l1.09-.63c.4-.23.59-.69.47-1.13-.17-.62-.26-1.25-.26-1.86s.09-1.23.26-1.86c.12-.44-.07-.9-.47-1.13l-1.09-.63a1.01 1.01 0 01-.37-1.37.998.998 0 011.37-.37l1.09.63c.4.23.89.16 1.21-.17.89-.91 2.01-1.56 3.25-1.88.44-.11.75-.51.75-.97V2.99c0-.55.45-1 1-1s1 .45 1 1v1.26c0 .46.31.85.75.97 1.24.32 2.36.97 3.25 1.88.32.33.82.4 1.21.17l1.09-.63c.23-.13.5-.17.76-.1s.47.24.61.47c.27.48.11 1.09-.37 1.37l-1.09.63c-.4.23-.59.69-.47 1.13zM13.78 8.2l-1.6 8c-.09.48-.51.8-.98.8-.06 0-.13 0-.2-.02a.997.997 0 01-.78-1.18l1.6-8c.11-.54.63-.89 1.18-.78.54.11.89.63.78 1.18zm-4.57 2.65L8 12.06l1.21 1.19a.996.996 0 01-.71 1.7c-.26 0-.51-.1-.71-.29l-1.21-1.21a1.98 1.98 0 010-2.81l1.21-1.21a.996.996 0 111.41 1.41zm8.21-.2c.78.78.78 2.04 0 2.81l-1.21 1.21c-.2.2-.45.29-.71.29s-.51-.1-.71-.29a.996.996 0 010-1.41L16 12.05l-1.21-1.2a.996.996 0 111.41-1.41l1.21 1.21z' />
  </svg>
)


export const Plus5SecIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='35'
    height='31'
    viewBox='0 0 35 31'
    fill='none'
  >
    <path
      d='M17.0949 25.352H14.8509V27.62H13.9629V25.352H11.8149V24.488H13.9629V22.22H14.8509V24.488H17.0949V25.352ZM20.7277 24.212C21.5277 24.212 22.1597 24.424 22.6237 24.848C23.0957 25.272 23.3317 25.856 23.3317 26.6C23.3317 27.344 23.0757 27.948 22.5637 28.412C22.0597 28.868 21.3877 29.096 20.5477 29.096C19.7157 29.096 19.0237 28.96 18.4717 28.688V27.752C18.9837 28.056 19.6037 28.208 20.3317 28.208C21.6757 28.208 22.3477 27.644 22.3477 26.516C22.3477 26.06 22.1917 25.708 21.8797 25.46C21.5677 25.204 21.1397 25.076 20.5957 25.076C20.0597 25.076 19.4797 25.216 18.8557 25.496V21.152H22.7917V21.98H19.7917V24.332C20.1517 24.252 20.4637 24.212 20.7277 24.212Z'
      fill='white'
    />
    <path
      d='M9 7.5C8.72386 7.5 8.5 7.72385 8.5 8C8.5 8.27614 8.72386 8.5 9 8.5L9 7.5ZM26.3536 8.35358C26.5488 8.15832 26.5488 7.84174 26.3536 7.64647L23.1716 4.46449C22.9763 4.26923 22.6597 4.26922 22.4645 4.46449C22.2692 4.65975 22.2692 4.97633 22.4645 5.17159L25.2929 8.00003L22.4645 10.8284C22.2692 11.0237 22.2692 11.3403 22.4645 11.5356C22.6597 11.7308 22.9763 11.7308 23.1716 11.5356L26.3536 8.35358ZM9 8.5L10.0625 8.5L10.0625 7.5L9 7.5L9 8.5ZM12.1875 8.5L14.3125 8.50001L14.3125 7.50001L12.1875 7.5L12.1875 8.5ZM16.4375 8.50001L18.5625 8.50001L18.5625 7.50001L16.4375 7.50001L16.4375 8.50001ZM20.6875 8.50002L22.8125 8.50002L22.8125 7.50002L20.6875 7.50002L20.6875 8.50002ZM24.9375 8.50002L26 8.50003L26 7.50003L24.9375 7.50002L24.9375 8.50002ZM9 7C8.44772 7 8 7.44771 8 8C8 8.55228 8.44771 9 9 9L9 7ZM26.7071 8.70713C27.0976 8.31661 27.0976 7.68345 26.7071 7.29292L20.3432 0.928949C19.9526 0.538424 19.3195 0.538423 18.9289 0.928947C18.5384 1.31947 18.5384 1.95264 18.9289 2.34316L24.5858 8.00002L18.9289 13.6569C18.5384 14.0474 18.5384 14.6806 18.9289 15.0711C19.3194 15.4616 19.9526 15.4616 20.3431 15.0711L26.7071 8.70713ZM9 9L10.0625 9L10.0625 7L9 7L9 9ZM12.1875 9L14.3125 9.00001L14.3125 7.00001L12.1875 7L12.1875 9ZM16.4375 9.00001L18.5625 9.00001L18.5625 7.00001L16.4375 7.00001L16.4375 9.00001ZM20.6875 9.00002L22.8125 9.00002L22.8125 7.00002L20.6875 7.00002L20.6875 9.00002ZM24.9375 9.00002L26 9.00003L26 7.00003L24.9375 7.00002L24.9375 9.00002Z'
      fill='white'
    />
  </svg>
);

import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react'
import { useSearchParams } from 'react-router-dom';
import { useLexicon } from 'lib/hooks/useTranslates';
import { IconComponent } from 'assets/icons';
import { Modal } from 'components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { teamActions, teamSlice } from 'store/team/teamSlice';
import { getTeamStatistics } from 'store/team/teamService';
import { getPlayerStatistics } from 'store/player/playerService';
import {
  MatchesSettings,
} from 'components/TeamProfileComponents/TeamProfileEvents/MatchesSettings';
import {
  useMatchesSettings,
} from 'components/TeamProfileComponents/TeamProfileEvents/MatchesSettings/hooks';
import {
  Select,
  UiDropdown,
} from '../../../ui';
import { EventsSelector } from './EventsSelector/EventsSelector';
import cls from './SelectEventsCard.module.css';

const ALL_COMPETITIONS_KEY = 'all competitions'
const PRE_SET_MATCHES = [
  {
    checked: false,
    label: 'last 3 matches',
    value: 3,
  },
  {
    checked: false,
    label: 'last 5 matches',
    value: 5,
  },
  {
    checked: false,
    label: 'last 10 matches',
    value: 10,
  },
]

export const SelectEventsCard = () => {
  const l = useLexicon();
  const [searchParams] = useSearchParams();
  const playerStatistic = useSelector((state) => state.player.playerStatistic);
  const {
    competitions,
    handleChangeSeason,
    handleResetSettings,
    handleSaveSettings,
    handleSelectedCompetition,
    isOpenSettings,
    openSettings,
    seasons,
    selectedCountMatches,
    selectOptions,
    setIsOpenSettings,
    setSelectedCountMatches,
  } = useMatchesSettings({ selectMatchesOptions: [] })

  const isPlayerGoalkeeper = useMemo(() => {
    if (playerStatistic?.player?.position?.length > 0) {
      const isGK = playerStatistic.player.position.findIndex((item) => item.id === 10)
      return isGK >= 0;
    }
    return false
  }, [playerStatistic])

  const isTeamPage = Boolean(searchParams.get('team'))

  return (
    <div className={cls.container}>
      <div className={cls.settings} onClick={openSettings}>
        <IconComponent.SETTINGS />
      </div>
      <header>{l(15)}</header>
      <Select
        options={selectOptions}
        styles={{
          marginBottom: 15,
          marginLeft: 10,
          width: '98%',
        }}
      />
      <EventsSelector isTeamPage={isTeamPage} isPlayerGoalkeeper={isPlayerGoalkeeper} />
      {isOpenSettings ? (
        <MatchesSettings
          withClose
          className={cls.team_matchesSettings}
          competitions={competitions}
          handleChangeSeason={handleChangeSeason}
          handleResetSettings={handleResetSettings}
          handleSaveSettings={handleSaveSettings}
          handleSelectedCompetition={handleSelectedCompetition}
          seasons={seasons}
          selectedCountMatches={selectedCountMatches}
          setIsOpenSettings={setIsOpenSettings}
          setSelectedCountMatches={setSelectedCountMatches}
        />
      ) : null}
    </div>
  )
}

export const PlayIcon = () => (
  <svg
    width={11}
    height={11}
    xmlns='http://www.w3.org/2000/svg'
    fill='#B4EB54'
    viewBox='0 0 24 24'
    strokeWidth={1}
    stroke='#B4EB54'
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z'
    />
  </svg>
);

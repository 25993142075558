export const Plus10SecIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='35'
    height='31'
    viewBox='0 0 35 31'
    fill='none'
  >
    <path
      d='M14.9445 25.352H12.7005V27.62H11.8125V25.352H9.66447V24.488H11.8125V22.22H12.7005V24.488H14.9445V25.352ZM18.5773 29H17.6413V22.184L15.8293 23.3V22.28L17.6893 21.152H18.5773V29ZM21.4962 22.772C21.1522 23.308 20.9802 24.068 20.9802 25.052C20.9802 26.036 21.1482 26.808 21.4842 27.368C21.8202 27.92 22.2482 28.196 22.7682 28.196C23.2962 28.196 23.7202 27.924 24.0402 27.38C24.3682 26.836 24.5322 26.064 24.5322 25.064C24.5322 24.056 24.3602 23.288 24.0162 22.76C23.6802 22.224 23.2602 21.956 22.7562 21.956C22.2602 21.956 21.8402 22.228 21.4962 22.772ZM22.7562 21.056C23.5882 21.056 24.2522 21.416 24.7482 22.136C25.2522 22.848 25.5042 23.82 25.5042 25.052C25.5042 26.276 25.2642 27.256 24.7842 27.992C24.3042 28.728 23.6362 29.096 22.7802 29.096C21.9242 29.096 21.2482 28.728 20.7522 27.992C20.2562 27.256 20.0082 26.276 20.0082 25.052C20.0082 23.82 20.2562 22.848 20.7522 22.136C21.2562 21.416 21.9242 21.056 22.7562 21.056Z'
      fill='white'
    />
    <path
      d='M9 7.5C8.72386 7.5 8.5 7.72385 8.5 8C8.5 8.27614 8.72386 8.5 9 8.5L9 7.5ZM26.3536 8.35358C26.5488 8.15832 26.5488 7.84174 26.3536 7.64647L23.1716 4.46449C22.9763 4.26923 22.6597 4.26922 22.4645 4.46449C22.2692 4.65975 22.2692 4.97633 22.4645 5.17159L25.2929 8.00003L22.4645 10.8284C22.2692 11.0237 22.2692 11.3403 22.4645 11.5356C22.6597 11.7308 22.9763 11.7308 23.1716 11.5356L26.3536 8.35358ZM9 8.5L10.0625 8.5L10.0625 7.5L9 7.5L9 8.5ZM12.1875 8.5L14.3125 8.50001L14.3125 7.50001L12.1875 7.5L12.1875 8.5ZM16.4375 8.50001L18.5625 8.50001L18.5625 7.50001L16.4375 7.50001L16.4375 8.50001ZM20.6875 8.50002L22.8125 8.50002L22.8125 7.50002L20.6875 7.50002L20.6875 8.50002ZM24.9375 8.50002L26 8.50003L26 7.50003L24.9375 7.50002L24.9375 8.50002ZM9 7C8.44772 7 8 7.44771 8 8C8 8.55228 8.44771 9 9 9L9 7ZM26.7071 8.70713C27.0976 8.31661 27.0976 7.68345 26.7071 7.29292L20.3432 0.928949C19.9526 0.538424 19.3195 0.538423 18.9289 0.928947C18.5384 1.31947 18.5384 1.95264 18.9289 2.34316L24.5858 8.00002L18.9289 13.6569C18.5384 14.0474 18.5384 14.6806 18.9289 15.0711C19.3194 15.4616 19.9526 15.4616 20.3431 15.0711L26.7071 8.70713ZM9 9L10.0625 9L10.0625 7L9 7L9 9ZM12.1875 9L14.3125 9.00001L14.3125 7.00001L12.1875 7L12.1875 9ZM16.4375 9.00001L18.5625 9.00001L18.5625 7.00001L16.4375 7.00001L16.4375 9.00001ZM20.6875 9.00002L22.8125 9.00002L22.8125 7.00002L20.6875 7.00002L20.6875 9.00002ZM24.9375 9.00002L26 9.00003L26 7.00003L24.9375 7.00002L24.9375 9.00002Z'
      fill='white'
    />
  </svg>
);

import { TacticI } from '../types/tacticTypes';

export const tactics: TacticI = {
  '3-3-3-1': [
    'GK',
    'LCD',
    'CD',
    'RCD',
    'LDM',
    'CDM',
    'RDM',
    'RCAM',
    'CAM',
    'LCAM',
    'CF',
  ],
  '3-4-3': [
    'GK',
    'LCD',
    'CD',
    'RCD',
    'LDM',
    'LCDM',
    'RCDM',
    'RDM',
    'RCAM',
    'LCAM',
    'CF',
  ],
  '3-5-2': [
    'GK',
    'LCD',
    'CD',
    'RCD',
    'LDM',
    'LCDM',
    'RCDM',
    'RDM',
    'CAM',
    'LCF',
    'RCF',
  ],
  '3-5-2*': [
    'GK',
    'LCD',
    'CD',
    'RCD',
    'LDM',
    'CDM',
    'RDM',
    'RCM',
    'LCM',
    'LCF',
    'RCF',
  ],
  '4-1-4-1': [
    'GK',
    'LCD',
    'LD',
    'RCD',
    'RD',
    'CDM',
    'LCM',
    'LM',
    'RCM',
    'RM',
    'CF',
  ],
  '4-2-3-1': [
    'GK',
    'LCD',
    'LD',
    'RCD',
    'RD',
    'RCDM',
    'LCDM',
    'RAM',
    'CAM',
    'LAM',
    'CF',
  ],
  '4-3-3-Down': [
    'GK',
    'LCD',
    'LD',
    'RCD',
    'RD',
    'CDM',
    'RCM',
    'LCM',
    'RAM',
    'LAM',
    'CF',
  ],
  '4-4-2-Classic': [
    'GK',
    'LCD',
    'LD',
    'RCD',
    'RD',
    'RCDM',
    'LCDM',
    'LM',
    'RM',
    'LCF',
    'RCF',
  ],
  '4-4-2-Diamond': [
    'GK',
    'LCD',
    'LD',
    'RCD',
    'RD',
    'CDM',
    'LM',
    'RM',
    'CAM',
    'RCF',
    'LCF',
  ],
};

export const actionTactics = {
  '3-3-3-1': 103,
  '3-4-3': 90,
  '3-5-2': 106,
  '3-5-2*': 105,
  '4-1-4-1': 111,
  '4-2-3-1': 82,
  '4-3-3-Down': 3,
  '4-4-2-Classic': 96,
  '4-4-2-Diamond': 16,
}

export const availableTacticKeysIds = Object.values(actionTactics);

export const getPositionTranslate = (title) => [
  { lexic_id: 390, title: 'Defender - Left' },
  { lexic_id: 391, title: 'Midfielder - Left' },
  { lexic_id: 392, title: 'Attacking midfielder - Left' },
  { lexic_id: 393, title: 'Forward - Left' },
  { lexic_id: 394, title: 'Defender - Left central' },
  { lexic_id: 395, title: 'Defensive midfielder - Left central' },
  { lexic_id: 396, title: 'Midfielder - Left central' },
  { lexic_id: 397, title: 'Attacking midfielder - Left central' },
  { lexic_id: 398, title: 'Forward - Left central' },
  { lexic_id: 399, title: 'Goalkeeper' },
  { lexic_id: 400, title: 'Defender - Central' },
  { lexic_id: 401, title: 'Defensive midfielder - Central' },
  { lexic_id: 402, title: 'Midfielder - Central' },
  { lexic_id: 403, title: 'Attacking midfielder - Central' },
  { lexic_id: 404, title: 'Forward - Central' },
  { lexic_id: 405, title: 'Defender - Right central' },
  { lexic_id: 406, title: 'Defensive midfielder - Right central' },
  { lexic_id: 407, title: 'Midfielder - Right central' },
  { lexic_id: 408, title: 'Attacking midfielder - Right central' },
  { lexic_id: 409, title: 'Forward - Right central' },
  { lexic_id: 410, title: 'Defender - Right' },
  { lexic_id: 411, title: 'Midfielder - Right' },
  { lexic_id: 412, title: 'Attacking midfielder - Right' },
  { lexic_id: 413, title: 'Forward - Right' },
  { lexic_id: 414, title: 'Substitute player' }]?.find((item) => item.title === title)?.lexic_id

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { useEffect, useMemo, useState } from 'react'
import { GearIcon } from 'components/ui/GearIcon/GearIcon'
import { useLexicon } from 'lib/hooks/useTranslates';
import { EyeIcon } from 'components/ui/EyeIcon/EyeIcon';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { TDTParametrs } from 'components/TeamProfileComponents/TeamProfileMatchesTable/TDTParametrs';
import { $api } from 'config/api';
import { getGroupedAndSortedParametrs } from 'lib/helpers/getGroupedAndSortedParametrs';
import cls from './MatchPlayers.module.css'

export const MatchPlayers = () => {
  const [showModal, setShowModal] = useState(false);
  const [teamParams, setTeamParams] = useState([]);
  const [playerParams, setPlayerParams] = useState([]);
  const handleClickGear = () => setShowModal(!showModal)
  const l = useLexicon();
  const match = useSelector((state: any) => state?.match)
  const full = useSelector((state: any) => state?.match?.fullStatistics)
  const loading = useSelector((state: any) => state?.match.loadingMatch);
  const handleClickConfirm = () => {
    handleClickGear()
  }

  const groupedAndSortedTeamParams = useMemo(() => {
    if (teamParams?.length > 0) {
      return getGroupedAndSortedParametrs(teamParams);
    }
    return [];
  }, [teamParams]);

  const groupedAndSortedPlayerParams = useMemo(() => {
    if (playerParams?.length > 0) {
      return getGroupedAndSortedParametrs(playerParams);
    }
    return [];
  }, [playerParams]);

  useEffect(() => {
    const getTeamParams = async () => {
      try {
        const response = await $api.get('/stats/parameters/team/');
        const data = response.data?.results;
        if (data) {
          setTeamParams(data);
        }
      } catch (e) { /* empty */ }
    }
    const getPlayerParams = async () => {
      try {
        const response = await $api.get('/stats/parameters/player/');
        const data = response.data?.results;
        if (data) {
          setPlayerParams(data);
        }
      } catch (e) { /* empty */ }
    }

    getPlayerParams();
    getTeamParams();
  }, [])
  const handleCheckEvent = (item: any, checked: any) => {
    // eslint-disable-next-line no-param-reassign
    item.checked = !checked;
  };

  return (
    <div className={cls.wrapper}>
      <div className={cls.header}>
        <span />
        <span>{l(5)}</span>
        <div onClick={handleClickGear}><GearIcon /></div>
      </div>
      {
        !loading && (
          <div className={cls.players}>
            <PlayersTable team={full?.statistics?.home_team_player_stats} squad={match?.match?.away_squad} />
            <PlayersTable team={full?.statistics?.away_team_player_stats} squad={match?.match?.home_squad} />
          </div>
        )
      }
      {showModal &&
      <div className={cls.modalGear}>
        {groupedAndSortedPlayerParams.map(([key, value]) => (
          <TDTParametrs
            array={value}
            title={key}
            key={key}
            callback={handleCheckEvent}
          />
        ))}
        <button className={cls.confirmBtn} type='button' onClick={handleClickConfirm}>confirm</button>
      </div>}
    </div>)
}

const PlayersTable = ({ squad, team }: { squad: any, team: any }) => {
  const [state, setState] = useState();
  return (
    <div>
      <div className={cls.playersHeading}>
        <div className={cls.leftStats}>
          <span>№</span>
          <span>Names</span>
          <span><EyeIcon /></span>
        </div>
        <ul className={cls.rightStats}>
          {params.map((item) => <ListItem key={item.id} id={item.id} title={item.title} />)}
        </ul>
      </div>
      <div className={cls.statsWrapper}>
        <div className={cls.teamName} style={{ backgroundColor: squad?.color, color: squad?.colorNumber }}><img src={squad?.team?.logo} width='45' height='45' alt='teamLogo' /><div className={cls.text}>{squad?.team?.name}</div></div>
        <div style={{ flex: 1 }}>
          {team?.map((item: any) => (
            <div className={cls.row}>
              <div className={cls.rowLeftStats}>
                <span style={{ width: '15px' }}>{item.number}</span>
                <Link to={`/player/${item.id}`} style={{ width: '350px' }}>{item.display_name}</Link>
                <span style={{ fontWeight: 'bold' }}>{item.position}</span>
              </div>
              <ul className={cls.rowRightStats}>
                <li>{item.statistics.Mins ?? '-'}</li>
                <li>{item.statistics['Goals scored'] ?? '-'}</li>
                <li>{item.statistics.Assist ?? '-'}</li>
                <li>{item.statistics['Yellow cards'] ?? '-'}</li>
                <li>{item.statistics['Red cards'] ?? '-'}</li>
                <li>{item.statistics['Shots on target'] ?? '-'}</li>
                <li>{item.statistics['Shots on target,+'] ?? '-'}</li>
                <li>{item.statistics['Shots on target, %'] ?? '-'}</li>
                <li>{item.statistics.Offsides ?? '-'}</li>
                <li>{item.statistics.Fouls ?? '-'}</li>
                <li>{item.statistics.FS ?? '-'}</li>
              </ul>
            </div>))}
        </div>
      </div>
    </div>)
}

const ListItem = ({ id, title }: { id: number, title: string }) => {
  const [state, setState] = useState('false');
  return (
    <li style={{
      alignItems: 'center',
      display: 'flex',
      gap: '5px',
      lineHeight: '0',
    }}
    >
      {title} <Dot />
    </li>
  )
}

const Dot = () => (
  <svg width='7' height='7' viewBox='0 0 7 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='3.5' cy='3.5' r='3.5' fill='#B4EB54' />
  </svg>
)

const params = [
  { id: 0, title: 'MINS' },
  { id: 1, title: 'G' },
  { id: 2, title: 'AST' },
  { id: 3, title: 'YC' },
  { id: 4, title: 'RC' },
  { id: 5, title: 'SH' },
  { id: 6, title: 'SH+' },
  { id: 7, title: 'SH%' },
  { id: 8, title: 'OFF' },
  { id: 9, title: 'F' },
  { id: 10, title: 'FS' },
]

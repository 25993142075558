export const playerEventsParams = [
  {
    actions: [
      {
        id: 14,
        lexic_id: null,
        title: 'On-Ball Events',
      },
      {
        id: 15,
        lexic_id: null,
        title: 'On-Ball Events +',
      },
      {
        id: 17,
        lexic_id: null,
        title: 'On-Ball Events -',
      },
      {
        id: 19,
        lexic_id: null,
        title: 'Fouls',
      },
      {
        id: 20,
        lexic_id: null,
        title: 'Fouls Suffered',
      },
      {
        id: 21,
        lexic_id: null,
        title: 'Yellow Cards',
      },
      {
        id: 22,
        lexic_id: null,
        title: 'Yellow-Red Cards',
      },
      {
        id: 23,
        lexic_id: null,
        title: 'Red Cards',
      },
      {
        id: 163,
        lexic_id: null,
        title: 'Offsides',
      },
      {
        id: 161,
        lexic_id: null,
        title: 'Goal Mistakes',
      },
      {
        id: 162,
        lexic_id: null,
        title: 'Grave Mistakes',
      },
    ],
    id: 1,
    title: 'General',
  },
  {
    actions: [
      {
        id: 2,
        lexic_id: null,
        title: 'Goals +',
      },
      {
        id: 3,
        lexic_id: null,
        title: 'Goals Penalties +',
      },
      {
        id: 4,
        lexic_id: null,
        title: 'Goal Chance',
      },
      {
        id: 5,
        lexic_id: null,
        title: 'Goal Chance +',
      },
      {
        id: 7,
        lexic_id: null,
        title: 'Goal Chance -',
      },
      {
        id: 9,
        lexic_id: null,
        title: 'Goal Chance Created',
      },
      {
        id: 10,
        lexic_id: null,
        title: 'Goal Chance Created +',
      },
      {
        id: 12,
        lexic_id: null,
        title: 'Goal Chance Created -',
      },
      {
        id: 24,
        lexic_id: null,
        title: 'Shots',
      },
      {
        id: 25,
        lexic_id: null,
        title: 'Shots on Target',
      },
      {
        id: 27,
        lexic_id: null,
        title: 'Shots Wide',
      },
      {
        id: 29,
        lexic_id: null,
        title: 'Shots Blocked',
      },
      {
        id: 31,
        lexic_id: null,
        title: 'Shots on Post or Bar',
      },
      {
        id: 33,
        lexic_id: null,
        title: 'Shots from free kick',
      },
    ],
    id: 2,
    title: 'Goals/Chances/Shots',
  },
  {
    actions: [
      {
        id: 35,
        lexic_id: null,
        title: 'Passes',
      },
      {
        id: 36,
        lexic_id: null,
        title: 'Passes +',
      },
      {
        id: 38,
        lexic_id: null,
        title: 'Passes -',
      },
      {
        id: 40,
        lexic_id: null,
        title: 'Passes in challenge',
      },
      {
        id: 41,
        lexic_id: null,
        title: 'Passes in challenge +',
      },
      {
        id: 43,
        lexic_id: null,
        title: 'Passes in challenge -',
      },
      {
        id: 50,
        lexic_id: null,
        title: 'Crosses',
      },
      {
        id: 51,
        lexic_id: null,
        title: 'Crosses +',
      },
      {
        id: 53,
        lexic_id: null,
        title: 'Crosses -',
      },
      {
        id: 90,
        lexic_id: null,
        title: 'Passes Recieved',
      },
      {
        id: 91,
        lexic_id: null,
        title: 'Passes in challenge Received',
      },
      {
        id: 95,
        lexic_id: null,
        title: 'Crosses Received',
      },
    ],
    id: 3,
    title: 'Passes',
  },
  {
    actions: [
      {
        id: 55,
        lexic_id: null,
        title: 'Short Passes',
      },
      {
        id: 56,
        lexic_id: null,
        title: 'Short Passes +',
      },
      {
        id: 58,
        lexic_id: null,
        title: 'Short Passes -',
      },
      {
        id: 60,
        lexic_id: null,
        title: 'Middle Passes',
      },
      {
        id: 61,
        lexic_id: null,
        title: 'Middle Passes +',
      },
      {
        id: 63,
        lexic_id: null,
        title: 'Middle Passes -',
      },
      {
        id: 65,
        lexic_id: null,
        title: 'Long Passes',
      },
      {
        id: 66,
        lexic_id: null,
        title: 'Long Passes +',
      },
      {
        id: 68,
        lexic_id: null,
        title: 'Long Passes -',
      },
      {
        id: 97,
        lexic_id: null,
        title: 'Short Passes Received',
      },
      {
        id: 99,
        lexic_id: null,
        title: 'Middle Passes Received',
      },
      {
        id: 101,
        lexic_id: null,
        title: 'Long Passes Received',
      },
    ],
    id: 4,
    title: 'Passes by distance',
  },
  {
    actions: [
      {
        id: 70,
        lexic_id: null,
        title: 'Passes Forward',
      },
      {
        id: 71,
        lexic_id: null,
        title: 'Passes Forward +',
      },
      {
        id: 73,
        lexic_id: null,
        title: 'Passes Forward -',
      },
      {
        id: 75,
        lexic_id: null,
        title: 'Passes Backward',
      },
      {
        id: 76,
        lexic_id: null,
        title: 'Passes Backward +',
      },
      {
        id: 78,
        lexic_id: null,
        title: 'Passes Backward -',
      },
      {
        id: 80,
        lexic_id: null,
        title: 'Passes Sideways',
      },
      {
        id: 81,
        lexic_id: null,
        title: 'Passes Sideways +',
      },
      {
        id: 83,
        lexic_id: null,
        title: 'Passes Sideways -',
      },
      {
        id: 103,
        lexic_id: null,
        title: 'Passes Forward Received',
      },
      {
        id: 105,
        lexic_id: null,
        title: 'Passes Backward Received',
      },
      {
        id: 107,
        lexic_id: null,
        title: 'Passes Sideways Received',
      },
    ],
    id: 5,
    title: 'Passes by side',
  },
  {
    actions: [
      {
        id: 201,
        lexic_id: null,
        title: 'Assists',
      },
      {
        id: 45,
        lexic_id: null,
        title: 'Key Passes',
      },
      {
        id: 46,
        lexic_id: null,
        title: 'Key Passes +',
      },
      {
        id: 48,
        lexic_id: null,
        title: 'Key Passes -',
      },
      {
        id: 85,
        lexic_id: null,
        title: 'Progressive Passes',
      },
      {
        id: 86,
        lexic_id: null,
        title: 'Progressive Passes +',
      },
      {
        id: 88,
        lexic_id: null,
        title: 'Progressive Passes -',
      },
      {
        id: 93,
        lexic_id: null,
        title: 'Key Passes Received',
      },
      {
        id: 109,
        lexic_id: null,
        title: 'Progressive Passes Received',
      },
    ],
    id: 6,
    title: 'Advanced passes',
  },
  {
    actions: [
      {
        id: 122,
        lexic_id: null,
        title: 'Duels',
      },
      {
        id: 123,
        lexic_id: null,
        title: 'Duels +',
      },
      {
        id: 125,
        lexic_id: null,
        title: 'Duels -',
      },
      {
        id: 127,
        lexic_id: null,
        title: 'Aerial Duels',
      },
      {
        id: 128,
        lexic_id: null,
        title: 'Aerial Duels +',
      },
      {
        id: 130,
        lexic_id: null,
        title: 'Aerial Duels -',
      },
      {
        id: 132,
        lexic_id: null,
        title: 'Duels in Offence',
      },
      {
        id: 133,
        lexic_id: null,
        title: 'Duels in Offence +',
      },
      {
        id: 135,
        lexic_id: null,
        title: 'Duels in Offence -',
      },
      {
        id: 137,
        lexic_id: null,
        title: 'Duels in Defense',
      },
      {
        id: 138,
        lexic_id: null,
        title: 'Duels in Defense +',
      },
      {
        id: 140,
        lexic_id: null,
        title: 'Duels in Defense -',
      },
    ],
    id: 7,
    title: 'Duels',
  },
  {
    actions: [
      {
        id: 142,
        lexic_id: null,
        title: 'Tackles Made',
      },
      {
        id: 143,
        lexic_id: null,
        title: 'Tackles Made +',
      },
      {
        id: 145,
        lexic_id: null,
        title: 'Tackles Made -',
      },
      {
        id: 152,
        lexic_id: null,
        title: 'Interception',
      },
      {
        id: 153,
        lexic_id: null,
        title: 'Interception +',
      },
      {
        id: 155,
        lexic_id: null,
        title: 'Interception -',
      },
      {
        id: 157,
        lexic_id: null,
        title: 'Blocked shots',
      },
      {
        id: 160,
        lexic_id: null,
        title: 'Clearances',
      },
      {
        id: 117,
        lexic_id: null,
        title: 'Dribbles Faced',
      },
      {
        id: 118,
        lexic_id: null,
        title: 'Dribbles Faced +',
      },
      {
        id: 120,
        lexic_id: null,
        title: 'Dribbles Faced -',
      },
    ],
    id: 8,
    title: 'In defence',
  },
  {
    actions: [
      {
        id: 112,
        lexic_id: null,
        title: 'Dribbles Made',
      },
      {
        id: 113,
        lexic_id: null,
        title: 'Dribbles Made +',
      },
      {
        id: 115,
        lexic_id: null,
        title: 'Dribbles Made -',
      },
      {
        id: 111,
        lexic_id: null,
        title: 'Progressive Carries',
      },
      {
        id: 147,
        lexic_id: null,
        title: 'Tackles Faced',
      },
      {
        id: 148,
        lexic_id: null,
        title: 'Tackles Faced +',
      },
      {
        id: 150,
        lexic_id: null,
        title: 'Tackles Faced -',
      },
    ],
    id: 10,
    title: 'In offence',
  },
  {
    actions: [
      {
        id: 158,
        lexic_id: null,
        title: 'Ball Recoveries',
      },
      {
        id: 159,
        lexic_id: null,
        title: 'Ball Losses',
      },
    ],
    id: 11,
    title: 'Ball possession',
  },
];


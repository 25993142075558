/* eslint-disable no-alert */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLexicon } from 'lib/hooks/useTranslates';
import { VIDEO_SERVICE_URL } from '../../../../config/api';
import { Spinner } from '../../../ui';
import cls from './VideoCutsActionBtns.module.css';

export const VideoCutsActionBtns = ({ showAddPlayList }) => {
  const selectedCuts = useSelector((state) => state.video_cuts.selectedCuts);
  const videoCuts = useSelector((state) => state.video_cuts.videoCuts);
  const formationInfo = useSelector((state) => state.user.userProfile);
  const [loadingDownloadCuts, setLoadingDownloadCuts] = useState(false);
  const l = useLexicon();

  const groupBy = (arr, key) => arr.reduce((rv, x) => {
    // eslint-disable-next-line no-param-reassign
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

  const prepeareCutsForServer = (data) => {
    const groupCutsByVideoname = groupBy(data, 'video');

    // eslint-disable-next-line consistent-return,array-callback-return
    const cuts = Object.entries(groupCutsByVideoname).map(([key, values]) => {
      // const pathArrFromStr = key.split("/");
      // const path = pathArrFromStr[pathArrFromStr.length - 1];
      const path = decodeURI(key);
      const seconds = values.map((item) => [
        Math.round(+item.second),
        item.title,
      ]);
      if (key && seconds.length > 0) {
        return {
          offset_range_begin: formationInfo.offset_range_begin,
          offset_range_end: formationInfo.offset_range_end,
          path,
          seconds,
        };
      }
    });
    return cuts;
  };

  const handleDownloadSelectedCuts = async () => {
    setLoadingDownloadCuts(true);
    try {
      const data = selectedCuts?.length === 0 ? videoCuts : selectedCuts;
      const cuts = prepeareCutsForServer(
        data.filter((item) => Boolean(item.video)),
      );
      if (cuts.length > 0) {
        const url = `${VIDEO_SERVICE_URL}/cut_videos/`;
        const response = await fetch(url, {
          body: JSON.stringify(cuts),
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
        });
        const json = await response.json();

        if (json.status === 'ok') {
          const a = document.createElement('a');
          a.href = `${VIDEO_SERVICE_URL}/video/${json.out_file}`;
          a.download = json.out_file;
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else if (json.status === 'error') {
          alert(
            json.input_path ||
              json.seconds ||
              json.out_file ||
              json.content_type,
          );
        } else {
          alert('Get zip archive error');
        }
      } else {
        alert('There are no videos available for download');
      }
    } catch (e) {
      alert(`Get zip archive error, ${e.message}`);
    } finally {
      setLoadingDownloadCuts(false);
    }
  };

  return (
    <header className={cls.videoCutsActionBtnsContainer}>
      <button type='button' onClick={showAddPlayList}>
        {l(426)}
      </button>
      <button
        type='button'
        disabled={
          (!selectedCuts?.length || loadingDownloadCuts) && !videoCuts?.length
        }
        onClick={() => handleDownloadSelectedCuts()}
      >
        {selectedCuts?.length > 0
          ? 'download selected videos'
          : l(427)}
      </button>
    </header>
  );
};

/* eslint-disable */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Skeleton } from '../../../ui';
import cls from './TeamInfo.module.css';
import smrtPlaceholder from '../../../../images/smrt_placeholder.jpg';
import { useLexicon } from 'lib/hooks/useTranslates';
import { StatItem } from './StatItem';

export const TeamInfo = () => {
  const { t } = useTranslation();
  const loading = useSelector((state) => state.team.loadingTeamStatistic);
  const team = useSelector((state) => state.team.teamStatistic?.team);
  const stat = useSelector((state) => state.team.teamStatistic);
  const navigate = useNavigate();
  const l = useLexicon();
  const teamLogo = useMemo(() => {
    if (team?.logo) {
      return team.logo;
    }
    return smrtPlaceholder;
  }, [team]);
  // список лексик для статистики из сервиса,
  const arrStatistics = [l(102), l(103), l(102), l(105), l(106), l(107), l(17), l(47), l(108)];
  // имена полей статистики сейчас выводим из ключей обьекта STAT 
  // console.log(stat?.statistics.fillter((item) => ))
  // const keys = Object.keys(stat?.statistics);
  // const values = Object.values(stat?.statistics);
  // console.log(keys)
  return (
    <article className={cls.teamInfoContainer}>
      {loading ? (
        <Skeleton width={258} height={200} />
      ) : (
        <img
          // eslint-disable-next-line no-return-assign
          onError={(e) => (e.target.src = smrtPlaceholder)}
          src={teamLogo}
          alt='team logo'
        />
      )}

      <Link className={cls.teamInfoContainerLink} to={`/team/${team?.id}`}>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <h3
          onClick={() => team?.id && navigate(`/team/${team?.id}`)}
        >
          {loading ? <Skeleton width={159} height={34} /> : team?.name}
        </h3>
      </Link>
      <div>
        <h4>{l(120)}</h4>
        <ul>
          {loading ? (
            <Skeleton width={258} height={400} />
          ) : (
            <>
              <li className={cls.underline}>{l(100)}:</li>
              {stat?.matches_played?.length > 0 ? (
                <>
                  {stat.matches_played.map((item, idx) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={idx}>
                      {item.competition__name} ({item.title}):{' '}
                      {item.matches_count}
                    </li>
                  ))}
                </>
              ) : (
                <li>-</li>
              )}
              <li className={cls.underline}>{l(101)}:</li>
              {stat?.statistics
              && Object.entries(stat?.statistics).length > 0 ? (
                  <>
                  {Object.entries(stat.statistics).map(([key, value]) => (
                      <StatItem key={key} field={key} value={value}/>
                    ))}
                </>
                ) : (
                  <li>-</li>
                )}
            </>
          )}
        </ul>
      </div>
    </article>
  );
};

export const Minus10SecIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='35'
    height='31'
    viewBox='0 0 35 31'
    fill='none'
  >
    <path
      d='M13.7029 26.192H10.8949V25.364H13.7029V26.192ZM17.3468 29H16.4108V22.184L14.5988 23.3V22.28L16.4588 21.152H17.3468V29ZM20.2657 22.772C19.9217 23.308 19.7497 24.068 19.7497 25.052C19.7497 26.036 19.9177 26.808 20.2537 27.368C20.5897 27.92 21.0177 28.196 21.5377 28.196C22.0657 28.196 22.4897 27.924 22.8097 27.38C23.1377 26.836 23.3017 26.064 23.3017 25.064C23.3017 24.056 23.1297 23.288 22.7857 22.76C22.4497 22.224 22.0297 21.956 21.5257 21.956C21.0297 21.956 20.6097 22.228 20.2657 22.772ZM21.5257 21.056C22.3577 21.056 23.0217 21.416 23.5177 22.136C24.0217 22.848 24.2737 23.82 24.2737 25.052C24.2737 26.276 24.0337 27.256 23.5537 27.992C23.0737 28.728 22.4057 29.096 21.5497 29.096C20.6937 29.096 20.0177 28.728 19.5217 27.992C19.0257 27.256 18.7777 26.276 18.7777 25.052C18.7777 23.82 19.0257 22.848 19.5217 22.136C20.0257 21.416 20.6937 21.056 21.5257 21.056Z'
      fill='white'
    />
    <path
      d='M26 8.5C26.2761 8.5 26.5 8.27615 26.5 8C26.5 7.72386 26.2761 7.5 26 7.5L26 8.5ZM8.64645 7.64642C8.45119 7.84169 8.45118 8.15827 8.64645 8.35353L11.8284 11.5355C12.0237 11.7308 12.3403 11.7308 12.5355 11.5355C12.7308 11.3403 12.7308 11.0237 12.5355 10.8284L9.70711 7.99998L12.5355 5.17156C12.7308 4.97629 12.7308 4.65971 12.5355 4.46445C12.3403 4.26919 12.0237 4.26919 11.8284 4.46445L8.64645 7.64642ZM26 7.5L24.9375 7.5L24.9375 8.5L26 8.5L26 7.5ZM22.8125 7.5L20.6875 7.49999L20.6875 8.49999L22.8125 8.5L22.8125 7.5ZM18.5625 7.49999L16.4375 7.49999L16.4375 8.49999L18.5625 8.49999L18.5625 7.49999ZM14.3125 7.49999L12.1875 7.49998L12.1875 8.49998L14.3125 8.49999L14.3125 7.49999ZM10.0625 7.49998L9 7.49998L9 8.49998L10.0625 8.49998L10.0625 7.49998ZM26 9C26.5523 9 27 8.55229 27 8C27 7.44772 26.5523 7 26 7L26 9ZM8.29289 7.29287C7.90237 7.68339 7.90237 8.31656 8.29289 8.70708L14.6568 15.0711C15.0474 15.4616 15.6805 15.4616 16.0711 15.0711C16.4616 14.6805 16.4616 14.0474 16.0711 13.6568L10.4142 7.99998L16.0711 2.34313C16.4616 1.95261 16.4616 1.31945 16.0711 0.92892C15.6806 0.538395 15.0474 0.538394 14.6569 0.928918L8.29289 7.29287ZM26 7L24.9375 7L24.9375 9L26 9L26 7ZM22.8125 7L20.6875 6.99999L20.6875 8.99999L22.8125 9L22.8125 7ZM18.5625 6.99999L16.4375 6.99999L16.4375 8.99999L18.5625 8.99999L18.5625 6.99999ZM14.3125 6.99999L12.1875 6.99998L12.1875 8.99998L14.3125 8.99999L14.3125 6.99999ZM10.0625 6.99998L9 6.99998L9 8.99998L10.0625 8.99998L10.0625 6.99998Z'
      fill='white'
    />
  </svg>
);

/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState } from 'react';
import { teamSelectedParams } from 'config/parametrs/teamProfileParams';
import { TDTParametrs } from 'components/TeamProfileComponents/TeamProfileMatchesTable/TDTParametrs';
import { TDTableItem } from 'components/TeamProfileComponents/TeamProfileMatchesTable/TDTableItem';
import { Overlay, Select, Spinner } from 'components/ui';
import { useLexicon } from 'lib/hooks/useTranslates';
import { THTableItem } from 'components/TeamProfileComponents/TeamProfileMatchesTable/THTableItem';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import cls from './TournamentMatchesTable.module.css';

export const TournamnetMatchesTable = () => {
  const [openMatchSelector, setOpenMatchSelector] = useState(false);
  const [openParametrsSelector, setOpenParametrSelector] = useState(false);
  const matchesStat = useSelector(
    (state) => state.tournament?.tournaments?.matches,
  );
  const loading = useSelector((state) => state.tournament?.loadingTournaments);
  const paginateLoading = useSelector(
    (state) => state.tournament?.paginateLoading,
  );
  const { id } = useParams();
  const l = useLexicon();
  let selectParametrs = teamSelectedParams;

  if (!sessionStorage.getItem('teamParams')) {
    sessionStorage.setItem('teamParams', JSON.stringify(teamSelectedParams));
  } else {
    selectParametrs = JSON.parse(sessionStorage.getItem('teamParams'));
  }

  const selectedGroup1 = selectParametrs.filter(
    (item) => item.groupe === 'main',
  );
  const selectedGroup2 = selectParametrs.filter(
    (item) => item.groupe === 'shots',
  );
  const selectedGroup3 = selectParametrs.filter(
    (item) => item.groupe === 'ball movement',
  );
  const selectedGroup4 = selectParametrs.filter(
    (item) => item.groupe === 'ball possession',
  );
  const selectedGroup5 = selectParametrs.filter(
    (item) => item.groupe === 'duels',
  );

  const handleCheckEvent = (item, checked) => {
    // eslint-disable-next-line no-param-reassign
    item.checked = !checked;
  };
  const handleClickConfirm = () => {
    sessionStorage.setItem('teamParams', JSON.stringify(selectParametrs));
    setOpenParametrSelector(false);
  };

  // const preapreLink = (response) => {
  //   const fileUrl = response.data?.report;
  //   const fileLink = document.createElement('a');
  //   fileLink.href = fileUrl;
  //   fileLink.setAttribute('style', 'visibility: hidden');
  //   fileLink.setAttribute('download', 'report.xlsx');
  //   document.body.appendChild(fileLink);
  //   fileLink.click();
  //   fileLink.remove();
  //   // dispatch(teamActions.setSelectedMatches([]));
  // }

  // const postSelectedMatches = async () => {
  //   const preparedMatches = matchesStat.map((match) => match.id)
  //   try {
  //     const response = await $api.post(`/statistics/team_matches/${id}`, {
  //       matches: matchesStat.length ? matchesStat : preparedMatches,
  //       params: [],
  //     });
  //     preapreLink(response)
  //   } catch (error) {
  //     // eslint-disable-next-line no-console
  //     console.log(error);
  //   }
  // };

  return (
    <>
      <div className={cls.headerContainer}>
        <div className={cls.selectContainer}>
          <h2 className={cls.headerGameWeek}>Game Week</h2>
          {/* <select className={cls.headerSelect}>
            <option value='Group Stage'>Group Stage</option>
          </select> */}
        </div>
      </div>
      <div className={cls.tableWrapper}>
        {openMatchSelector && (
          <div className={cls.matchSelector}>
            <Select styles={{ margin: '0 20px' }} />
          </div>
        )}
        {openParametrsSelector && (
          <div className={cls.parametrsSelector}>
            <TDTParametrs
              array={selectedGroup1}
              title='Main'
              callback={handleCheckEvent}
            />
            <TDTParametrs
              array={selectedGroup2}
              title='Shots'
              callback={handleCheckEvent}
            />
            <TDTParametrs
              array={selectedGroup3}
              title='Ball movement'
              callback={handleCheckEvent}
            />
            <TDTParametrs
              array={selectedGroup4}
              title='Ball Possession'
              callback={handleCheckEvent}
            />
            <TDTParametrs
              array={selectedGroup5}
              title='Duels'
              callback={handleCheckEvent}
            />
            <button
              onClick={handleClickConfirm}
              className={cls.btnConfirm}
              type='button'
            >
              {l(446)}
            </button>
          </div>
        )}
        <div className={cls.tableActionBtns}>
          {/* <button
            type='button'
            className={openMatchSelector ? cls.activeBtn : ''}
            onClick={() => setOpenMatchSelector(true)}
          >
            {l(12)}
          </button> */}
          <button
            type='button'
            className={openParametrsSelector ? cls.activeBtn : ''}
            onClick={() => setOpenParametrSelector(true)}
          >
            {l(13)}
          </button>
          {/* раскомментировать, когда появиться возможность скачивать xls */}
          {/*           <button
            type='button'
            className={matchesStat.length < 0 ? cls.disableBtn : ''}
            onClick={() => postSelectedMatches()}
          >
            {l(14)}
          </button> */}
        </div>
        <div className={cls.tableContainer}>
          <table className={cls.matchesTable}>
            <thead>
              <tr>
                <th className={cls.fixDate} title='Date'>
                  {l(121)}
                </th>
                <th className={cls.fixOpponent} title={l(3)}>
                  {l(3)}
                </th>
                <th className={cls.fixScore} title={l(123)}>
                  {l(123)}
                </th>
                <THTableItem array={selectedGroup1} />
                <THTableItem array={selectedGroup2} />
                <THTableItem array={selectedGroup3} />
                <THTableItem array={selectedGroup4} />
                <THTableItem array={selectedGroup5} />
              </tr>
            </thead>
            <tbody>
              {/* eslint-disable-next-line no-nested-ternary */}
              {loading || paginateLoading ? (
                <tr style={{ textAlign: 'center' }}>
                  <td colSpan='41'>
                    <Spinner size='medium' />
                  </td>
                </tr>
              ) : matchesStat?.length > 0 ? (
                <>
                  {matchesStat.map((match) => (
                    <>
                      <tr key={match.id}>
                        <TDTableItem
                          style={{
                            background: 'var(--main-gray)',
                            left: '0px',
                            position: 'sticky',
                          }}
                          value={match.date?.split('T')?.[0]}
                          canHover={false}
                          rowSpan='2'
                          match={match}
                        />
                        <TDTableItem
                          style={{
                            background: 'var(--main-gray)',
                            left: '100px',
                            position: 'sticky',
                          }}
                          onClick={() => window.open(
                            `/team/match?team=${match.home_team.id}&match=${match?.id}`,
                            '_blank',
                          )}
                          value={match.home_team.name}
                          match={match}
                        />
                        <TDTableItem
                          style={{
                            background: 'var(--main-gray)',
                            color: '#1BDA32',
                            left: '300px',
                            position: 'sticky',
                          }}
                          value={match.home_team.stats['Goals scored']}
                          canHover={false}
                          match={match}
                          link={`/tournament/video/${id}?match=${match.id}`}
                        />
                        {selectParametrs[0].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Goals scored']}
                            match={match}
                            link={`/video_cuts?action=65&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[1].checked && (
                          <TDTableItem
                            value={match.home_team.stats.Assists}
                            match={match}
                            link={`/video_cuts?action=79&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[2].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Goals conceded']}
                            match={match}
                            link={`/video_cuts?action=65&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[3].checked && (
                          <TDTableItem
                            value={match.home_team.stats.Mistakes}
                            match={match}
                            link={`/video_cuts?action=61&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[4].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Yellow cards']}
                            match={match}
                            link={`/video_cuts?action=55&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[5].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Red cards']}
                            match={match}
                            link={`/video_cuts?action=86&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[6].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Goal mistakes']}
                            match={match}
                            link={`/video_cuts?action=69&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[7].checked && (
                          <TDTableItem
                            value={match.home_team.stats.Offsides}
                            match={match}
                            link={`/video_cuts?action=47&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[8].checked && (
                          <TDTableItem
                            value={match.home_team.stats.OBE}
                            match={match}
                            canHover={false}
                          />
                        )}
                        {selectParametrs[9].checked && (
                          <TDTableItem
                            value={match.home_team.stats['OBE +']}
                            match={match}
                            canHover={false}
                          />
                        )}
                        {selectParametrs[10].checked && (
                          <TDTableItem
                            value={match.home_team.stats['OBE +, %']}
                            match={match}
                            canHover={false}
                          />
                        )}
                        {selectParametrs[11].checked && (
                          <TDTableItem
                            value={match.home_team.stats.Fouls}
                            match={match}
                            link={`/video_cuts?action=51&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[12].checked && (
                          <TDTableItem
                            value={match.home_team.stats.Shots}
                            match={match}
                            link={`/video_cuts?action=70,44,53,97,65&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[13].checked && (
                          <TDTableItem
                            value={
                              match.home_team.stats['Shots on post or bar']
                            }
                            match={match}
                            link={`/video_cuts?action=97&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[14].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Shots on target']}
                            match={match}
                            link={`/video_cuts?action=70,65&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[15].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Shots on target, %']}
                            match={match}
                            canHover={false}
                          />
                        )}
                        {selectParametrs[16].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Shots wide']}
                            match={match}
                            link={`/video_cuts?action=44&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[17].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Key passes']}
                            match={match}
                            link={`/video_cuts?action=36,58,76&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[18].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Key passes +']}
                            match={match}
                            link={`/video_cuts?action=58,76&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[19].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Ball interceptions']}
                            match={match}
                            canHover={false}
                          />
                        )}
                        {selectParametrs[20].checked && (
                          <TDTableItem
                            value={
                              match.home_team.stats['Ball interceptions +']
                            }
                            match={match}
                            link={`/video_cuts?action=28,54&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[21].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Ball losses']}
                            match={match}
                            link={`/video_cuts?action=27&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[22].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Ball recoveries']}
                            match={match}
                            link={`/video_cuts?action=29&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[23].checked && (
                          <TDTableItem
                            value={
                              match.home_team.stats['Neutral ball recoveries']
                            }
                            match={match}
                            link={`/video_cuts?action=35&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[24].checked && (
                          <TDTableItem
                            value={match.home_team.stats.Chances}
                            match={match}
                            link={`/video_cuts?action=63,68&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[25].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Chances successful']}
                            match={match}
                            link={`/video_cuts?action=68&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[26].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Chances, %']}
                            match={match}
                            canHover={false}
                          />
                        )}
                        {selectParametrs[27].checked && (
                          <TDTableItem
                            value={match.home_team.stats.Duels}
                            match={match}
                            link={`/video_cuts?action=34&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[28].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Duels +']}
                            match={match}
                            link={`/video_cuts?action=30,34,45,50&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[29].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Duels +, %']}
                            match={match}
                            canHover={false}
                          />
                        )}
                        {selectParametrs[30].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Aerial duels']}
                            match={match}
                            link={`/video_cuts?action=30&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[31].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Aerial duels +']}
                            match={match}
                            link={`/video_cuts?action=30&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[32].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Aerial duels +, %']}
                            match={match}
                            canHover={false}
                          />
                        )}
                        {selectParametrs[33].checked && (
                          <TDTableItem
                            value={match.home_team.stats.Tackles}
                            match={match}
                            link={`/video_cuts?action=38&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[34].checked && (
                          <TDTableItem
                            value={match.home_team.stats['Tackles +']}
                            match={match}
                            canHover={false}
                          />
                        )}
                        {selectParametrs[35].checked && (
                          <TDTableItem
                            value={
                              match.home_team.stats['Dribbles past opponent']
                            }
                            match={match}
                            link={`/video_cuts?action=45,50&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[36].checked && (
                          <TDTableItem
                            value={
                              match.home_team.stats['Dribbles past opponent +']
                            }
                            match={match}
                            link={`/video_cuts?action=50&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[37].checked && (
                          <TDTableItem
                            value={
                              match.home_team.stats[
                                'Dribbles past opponent +, %'
                              ]
                            }
                            match={match}
                            canHover={false}
                          />
                        )}
                      </tr>
                      <tr>
                        <TDTableItem
                          style={{
                            background: 'var(--main-gray)',
                            left: '100px',
                            position: 'sticky',
                          }}
                          value={match.away_team.name}
                          match={match}
                          onClick={() => window.open(
                            `/team/match?team=${match.away_team.id}&match=${match?.id}`,
                            '_blank',
                          )}
                        />
                        <TDTableItem
                          style={{
                            background: 'var(--main-gray)',
                            color: '#1BDA32',
                            left: '300px',
                            position: 'sticky',
                          }}
                          value={match.away_team.stats['Goals scored']}
                          match={match}
                          canHover={false}
                          link={`/tournament/video/${id}?match=${match.id}`}
                        />
                        {selectParametrs[0].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Goals scored']}
                            match={match}
                            link={`/video_cuts?action=65&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[1].checked && (
                          <TDTableItem
                            value={match.away_team.stats.Assists}
                            match={match}
                            link={`/video_cuts?action=79&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[2].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Goals conceded']}
                            match={match}
                            link={`/video_cuts?action=65&match=${match?.id}&team=${match.home_team.id}`}
                          />
                        )}
                        {selectParametrs[3].checked && (
                          <TDTableItem
                            value={match.away_team.stats.Mistakes}
                            match={match}
                            link={`/video_cuts?action=61&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[4].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Yellow cards']}
                            match={match}
                            link={`/video_cuts?action=55&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[5].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Red cards']}
                            match={match}
                            link={`/video_cuts?action=86&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[6].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Goal mistakes']}
                            match={match}
                            link={`/video_cuts?action=69&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[7].checked && (
                          <TDTableItem
                            value={match.away_team.stats.Offsides}
                            match={match}
                            link={`/video_cuts?action=47&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[8].checked && (
                          <TDTableItem
                            value={match.away_team.stats.OBE}
                            match={match}
                            canHover={false}
                          />
                        )}
                        {selectParametrs[9].checked && (
                          <TDTableItem
                            value={match.away_team.stats['OBE +']}
                            match={match}
                            canHover={false}
                          />
                        )}
                        {selectParametrs[10].checked && (
                          <TDTableItem
                            value={match.away_team.stats['OBE +, %']}
                            match={match}
                            canHover={false}
                          />
                        )}
                        {selectParametrs[11].checked && (
                          <TDTableItem
                            value={match.away_team.stats.Fouls}
                            match={match}
                            link={`/video_cuts?action=51&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[12].checked && (
                          <TDTableItem
                            value={match.away_team.stats.Shots}
                            match={match}
                            link={`/video_cuts?action=70,44,53,97,65&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[13].checked && (
                          <TDTableItem
                            value={
                              match.away_team.stats['Shots on post or bar']
                            }
                            match={match}
                            link={`/video_cuts?action=97&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[14].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Shots on target']}
                            match={match}
                            link={`/video_cuts?action=70,65&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[15].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Shots on target, %']}
                            match={match}
                            canHover={false}
                          />
                        )}
                        {selectParametrs[16].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Shots wide']}
                            match={match}
                            link={`/video_cuts?action=44&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[17].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Key passes']}
                            match={match}
                            link={`/video_cuts?action=36,58,76&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[18].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Key passes +']}
                            match={match}
                            link={`/video_cuts?action=58,76&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[19].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Ball interceptions']}
                            match={match}
                            canHover={false}
                          />
                        )}
                        {selectParametrs[20].checked && (
                          <TDTableItem
                            value={
                              match.away_team.stats['Ball interceptions +']
                            }
                            match={match}
                            link={`/video_cuts?action=28,54&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[21].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Ball losses']}
                            match={match}
                            link={`/video_cuts?action=27&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[22].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Ball recoveries']}
                            match={match}
                            link={`/video_cuts?action=29&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[23].checked && (
                          <TDTableItem
                            value={
                              match.away_team.stats['Neutral ball recoveries']
                            }
                            match={match}
                            link={`/video_cuts?action=35&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[24].checked && (
                          <TDTableItem
                            value={match.away_team.stats.Chances}
                            match={match}
                            link={`/video_cuts?action=63,68&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[25].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Chances successful']}
                            match={match}
                            link={`/video_cuts?action=68&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[26].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Chances, %']}
                            match={match}
                            canHover={false}
                          />
                        )}
                        {selectParametrs[27].checked && (
                          <TDTableItem
                            value={match.away_team.stats.Duels}
                            match={match}
                            link={`/video_cuts?action=34&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[28].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Duels +']}
                            match={match}
                            link={`/video_cuts?action=30,34,38,45,50&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[29].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Duels +, %']}
                            match={match}
                            canHover={false}
                          />
                        )}
                        {selectParametrs[30].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Aerial duels']}
                            match={match}
                            link={`/video_cuts?action=30&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[31].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Aerial duels +']}
                            match={match}
                            link={`/video_cuts?action=30&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[32].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Aerial duels +, %']}
                            match={match}
                            canHover={false}
                          />
                        )}
                        {selectParametrs[33].checked && (
                          <TDTableItem
                            value={match.away_team.stats.Tackles}
                            match={match}
                            link={`/video_cuts?action=38&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[34].checked && (
                          <TDTableItem
                            value={match.away_team.stats['Tackles +']}
                            match={match}
                            canHover={false}
                          />
                        )}
                        {selectParametrs[35].checked && (
                          <TDTableItem
                            value={
                              match.away_team.stats['Dribbles past opponent']
                            }
                            match={match}
                            link={`/video_cuts?action=45,50&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[36].checked && (
                          <TDTableItem
                            value={
                              match.away_team.stats['Dribbles past opponent +']
                            }
                            match={match}
                            link={`/video_cuts?action=50&match=${match?.id}&team=${match.away_team.id}`}
                          />
                        )}
                        {selectParametrs[37].checked && (
                          <TDTableItem
                            value={
                              match.away_team.stats[
                                'Dribbles past opponent +, %'
                              ]
                            }
                            match={match}
                            canHover={false}
                          />
                        )}
                      </tr>
                    </>
                  ))}
                </>
              ) : (
                <tr style={{ textAlign: 'center' }}>
                  <td colSpan='16'>No matches</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <Overlay
          open={openMatchSelector}
          onClose={() => setOpenMatchSelector(false)}
        />
        <Overlay
          open={openParametrsSelector}
          onClose={() => setOpenParametrSelector(false)}
        />
      </div>
    </>
  );
};

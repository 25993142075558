export const playerGkEventsParams = [
  {
    actions: [
      {
        id: 173,
        lexic_id: null,
        title: 'Goals conceded',
      },
      {
        id: 161,
        lexic_id: null,
        title: 'Goal Mistakes',
      },
      {
        id: 162,
        lexic_id: null,
        title: 'Grave Mistakes',
      },
      {
        id: 14,
        lexic_id: null,
        title: 'On-Ball Events',
      },
      {
        id: 15,
        lexic_id: null,
        title: 'On-Ball Events +',
      },
      {
        id: 17,
        lexic_id: null,
        title: 'On-Ball Events -',
      },
      {
        id: 19,
        lexic_id: null,
        title: 'Fouls',
      },
      {
        id: 20,
        lexic_id: null,
        title: 'Fouls Suffered',
      },
      {
        id: 21,
        lexic_id: null,
        title: 'Yellow Cards',
      },
      {
        id: 22,
        lexic_id: null,
        title: 'Yellow-Red Cards',
      },
      {
        id: 23,
        lexic_id: null,
        title: 'Red Cards',
      },
    ],
    id: 1,
    title: 'General',
  },
  {
    actions: [
      {
        id: 171,
        lexic_id: null,
        title: 'Opponent\'s shots',
      },
      {
        id: 172,
        lexic_id: null,
        title: 'Opponent\'s shots on target',
      },
      {
        id: 174,
        lexic_id: null,
        title: 'Saved shots',
      },
      {
        id: 176,
        lexic_id: null,
        title: 'Super Saves',
      },
      {
        id: 196,
        lexic_id: null,
        title: 'GK saves with bouncing',
      },
      {
        id: 196,
        lexic_id: null,
        title: 'GK saves with bouncing +',
      },
      {
        id: 199,
        lexic_id: null,
        title: 'GK saves with bouncing -',
      },
      {
        id: 177,
        lexic_id: null,
        title: 'Penalty faced',
      },
      {
        id: 178,
        lexic_id: null,
        title: 'Goals conceded from penalty',
      },
      {
        id: 179,
        lexic_id: null,
        title: 'Saved penalties',
      },
    ],
    id: 2,
    title: 'Shots / Saves',
  },
  {
    actions: [
      {
        id: 181,
        lexic_id: null,
        title: 'GK Interceptions',
      },
      {
        id: 182,
        lexic_id: null,
        title: 'GK Interceptions +',
      },
      {
        id: 184,
        lexic_id: null,
        title: 'GK Interceptions -',
      },
      {
        id: 160,
        lexic_id: null,
        title: 'Clearances',
      },
    ],
    id: 3,
    title: 'GK Actions',
  },
  {
    actions: [
      {
        id: 35,
        lexic_id: null,
        title: 'Passes',
      },
      {
        id: 36,
        lexic_id: null,
        title: 'Passes +',
      },
      {
        id: 38,
        lexic_id: null,
        title: 'Passes -',
      },
      {
        id: 40,
        lexic_id: null,
        title: 'Passes in challenge',
      },
      {
        id: 41,
        lexic_id: null,
        title: 'Passes in challenge +',
      },
      {
        id: 43,
        lexic_id: null,
        title: 'Passes in challenge -',
      },
      {
        id: 186,
        lexic_id: null,
        title: 'GK Hand Passes',
      },
      {
        id: 187,
        lexic_id: null,
        title: 'GK Hand Passes +',
      },
      {
        id: 189,
        lexic_id: null,
        title: 'GK Hand Passes -',
      },
      {
        id: 90,
        lexic_id: null,
        title: 'Passes Recieved',
      },
      {
        id: 191,
        lexic_id: null,
        title: 'GK Goal Kick Passes',
      },
      {
        id: 192,
        lexic_id: null,
        title: 'GK Goal Kick Passes +',
      },
      {
        id: 194,
        lexic_id: null,
        title: 'GK Goal Kick Passes -',
      },
    ],
    id: 4,
    title: 'Passes',
  },
  {
    actions: [
      {
        id: 55,
        lexic_id: null,
        title: 'Short Passes',
      },
      {
        id: 56,
        lexic_id: null,
        title: 'Short Passes +',
      },
      {
        id: 58,
        lexic_id: null,
        title: 'Short Passes -',
      },
      {
        id: 60,
        lexic_id: null,
        title: 'Middle Passes',
      },
      {
        id: 61,
        lexic_id: null,
        title: 'Middle Passes +',
      },
      {
        id: 63,
        lexic_id: null,
        title: 'Middle Passes -',
      },
      {
        id: 65,
        lexic_id: null,
        title: 'Long Passes',
      },
      {
        id: 66,
        lexic_id: null,
        title: 'Long Passes +',
      },
      {
        id: 68,
        lexic_id: null,
        title: 'Long Passes -',
      },
      {
        id: 97,
        lexic_id: null,
        title: 'Short Passes Received',
      },
      {
        id: 99,
        lexic_id: null,
        title: 'Middle Passes Received',
      },
      {
        id: 101,
        lexic_id: null,
        title: 'Long Passes Received',
      },
    ],
    id: 5,
    title: 'Passes by distance',
  },
  {
    actions: [
      {
        id: 70,
        lexic_id: null,
        title: 'Passes Forward',
      },
      {
        id: 71,
        lexic_id: null,
        title: 'Passes Forward +',
      },
      {
        id: 73,
        lexic_id: null,
        title: 'Passes Forward -',
      },
      {
        id: 75,
        lexic_id: null,
        title: 'Passes Backward',
      },
      {
        id: 76,
        lexic_id: null,
        title: 'Passes Backward +',
      },
      {
        id: 78,
        lexic_id: null,
        title: 'Passes Backward -',
      },
      {
        id: 80,
        lexic_id: null,
        title: 'Passes Sideways',
      },
      {
        id: 81,
        lexic_id: null,
        title: 'Passes Sideways +',
      },
      {
        id: 83,
        lexic_id: null,
        title: 'Passes Sideways -',
      },
      {
        id: 103,
        lexic_id: null,
        title: 'Passes Forward Received',
      },
      {
        id: 105,
        lexic_id: null,
        title: 'Passes Backward Received',
      },
      {
        id: 107,
        lexic_id: null,
        title: 'Passes Sideways Received',
      },
    ],
    id: 6,
    title: 'Passes by side',
  },
];


export const Minus5SecIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='35'
    height='31'
    viewBox='0 0 35 31'
    fill='none'
  >
    <path
      d='M15.8533 26.192H13.0453V25.364H15.8533V26.192ZM19.4972 24.212C20.2972 24.212 20.9292 24.424 21.3932 24.848C21.8652 25.272 22.1012 25.856 22.1012 26.6C22.1012 27.344 21.8452 27.948 21.3332 28.412C20.8292 28.868 20.1572 29.096 19.3172 29.096C18.4852 29.096 17.7932 28.96 17.2412 28.688V27.752C17.7532 28.056 18.3732 28.208 19.1012 28.208C20.4452 28.208 21.1172 27.644 21.1172 26.516C21.1172 26.06 20.9612 25.708 20.6492 25.46C20.3372 25.204 19.9092 25.076 19.3652 25.076C18.8292 25.076 18.2492 25.216 17.6252 25.496V21.152H21.5612V21.98H18.5612V24.332C18.9212 24.252 19.2332 24.212 19.4972 24.212Z'
      fill='white'
    />
    <path
      d='M26 8.5C26.2761 8.5 26.5 8.27615 26.5 8C26.5 7.72386 26.2761 7.5 26 7.5L26 8.5ZM8.64645 7.64642C8.45119 7.84169 8.45118 8.15827 8.64645 8.35353L11.8284 11.5355C12.0237 11.7308 12.3403 11.7308 12.5355 11.5355C12.7308 11.3403 12.7308 11.0237 12.5355 10.8284L9.70711 7.99998L12.5355 5.17156C12.7308 4.97629 12.7308 4.65971 12.5355 4.46445C12.3403 4.26919 12.0237 4.26919 11.8284 4.46445L8.64645 7.64642ZM26 7.5L24.9375 7.5L24.9375 8.5L26 8.5L26 7.5ZM22.8125 7.5L20.6875 7.49999L20.6875 8.49999L22.8125 8.5L22.8125 7.5ZM18.5625 7.49999L16.4375 7.49999L16.4375 8.49999L18.5625 8.49999L18.5625 7.49999ZM14.3125 7.49999L12.1875 7.49998L12.1875 8.49998L14.3125 8.49999L14.3125 7.49999ZM10.0625 7.49998L9 7.49998L9 8.49998L10.0625 8.49998L10.0625 7.49998ZM26 9C26.5523 9 27 8.55229 27 8C27 7.44772 26.5523 7 26 7L26 9ZM8.29289 7.29287C7.90237 7.68339 7.90237 8.31656 8.29289 8.70708L14.6568 15.0711C15.0474 15.4616 15.6805 15.4616 16.0711 15.0711C16.4616 14.6805 16.4616 14.0474 16.0711 13.6568L10.4142 7.99998L16.0711 2.34313C16.4616 1.95261 16.4616 1.31945 16.0711 0.92892C15.6806 0.538395 15.0474 0.538394 14.6569 0.928918L8.29289 7.29287ZM26 7L24.9375 7L24.9375 9L26 9L26 7ZM22.8125 7L20.6875 6.99999L20.6875 8.99999L22.8125 9L22.8125 7ZM18.5625 6.99999L16.4375 6.99999L16.4375 8.99999L18.5625 8.99999L18.5625 6.99999ZM14.3125 6.99999L12.1875 6.99998L12.1875 8.99998L14.3125 8.99999L14.3125 6.99999ZM10.0625 6.99998L9 6.99998L9 8.99998L10.0625 8.99998L10.0625 6.99998Z'
      fill='white'
    />
  </svg>
);

export const RewindCircleIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='21'
    height='10'
    viewBox='0 0 21 10'
    fill='none'
  >
    <path
      d='M1.73359 5.71022C1.00904 5.33838 1.00904 4.3027 1.73359 3.93086L6.67803 1.39337C7.34349 1.05186 8.13462 1.53507 8.13462 2.28305L8.13462 7.35802C8.13462 8.106 7.34349 8.58922 6.67803 8.24771L1.73359 5.71022Z'
      fill='white'
    />
    <path
      d='M11.3747 5.71022C10.6502 5.33838 10.6502 4.3027 11.3747 3.93086L16.3191 1.39337C16.9846 1.05186 17.7757 1.53507 17.7757 2.28305L17.7757 7.35802C17.7757 8.106 16.9846 8.58922 16.3191 8.24771L11.3747 5.71022Z'
      fill='white'
    />
  </svg>
);

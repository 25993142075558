export const teamEventsParams = [
  {
    actions: [
      {
        id: 13,
        lexic_id: null,
        title: 'On-Ball Events',
      },
      {
        id: 14,
        lexic_id: null,
        title: 'On-Ball Events +',
      },
      {
        id: 16,
        lexic_id: null,
        title: 'On-Ball Events -',
      },
      {
        id: 18,
        lexic_id: null,
        title: 'Fouls',
      },
      {
        id: 19,
        lexic_id: null,
        title: 'Fouls Suffered',
      },
      {
        id: 20,
        lexic_id: null,
        title: 'Yellow Cards',
      },
      {
        id: 21,
        lexic_id: null,
        title: 'Yellow-Red Cards',
      },
      {
        id: 22,
        lexic_id: null,
        title: 'Red Cards',
      },
      {
        id: 145,
        lexic_id: null,
        title: 'Offsides',
      },
      {
        id: 143,
        lexic_id: null,
        title: 'Goal Mistakes',
      },
      {
        id: 144,
        lexic_id: null,
        title: 'Grave Mistakes',
      },
    ],
    id: 1,
    title: 'General',
  },
  {
    actions: [
      {
        id: 1,
        lexic_id: null,
        title: 'Goals +',
      },
      {
        id: 2,
        lexic_id: null,
        title: 'Goals -',
      },
      {
        id: 3,
        lexic_id: null,
        title: 'Goal Chance',
      },
      {
        id: 4,
        lexic_id: null,
        title: 'Goal Chance +',
      },
      {
        id: 6,
        lexic_id: null,
        title: 'Goal Chance -',
      },
      {
        id: 8,
        lexic_id: null,
        title: 'Goal Chance Allowed',
      },
      {
        id: 9,
        lexic_id: null,
        title: 'Goal Chance Allowed +',
      },
      {
        id: 11,
        lexic_id: null,
        title: 'Goal Chance Allowed -',
      },
      {
        id: 23,
        lexic_id: null,
        title: 'Shots',
      },
      {
        id: 24,
        lexic_id: null,
        title: 'Shots on Target',
      },
      {
        id: 26,
        lexic_id: null,
        title: 'Shots Wide',
      },
      {
        id: 28,
        lexic_id: null,
        title: 'Shots Blocked',
      },
      {
        id: 30,
        lexic_id: null,
        title: 'Shots on Post or Bar',
      },
      {
        id: 32,
        lexic_id: null,
        title: 'Shots from free kick',
      },
    ],
    id: 2,
    title: 'Goals/Chances/Shots',
  },
  {
    actions: [
      {
        id: 34,
        lexic_id: null,
        title: 'Passes',
      },
      {
        id: 35,
        lexic_id: null,
        title: 'Passes +',
      },
      {
        id: 37,
        lexic_id: null,
        title: 'Passes -',
      },
      {
        id: 39,
        lexic_id: null,
        title: 'Passes in challenge',
      },
      {
        id: 40,
        lexic_id: null,
        title: 'Passes in challenge +',
      },
      {
        id: 42,
        lexic_id: null,
        title: 'Passes in challenge -',
      },
      {
        id: 49,
        lexic_id: null,
        title: 'Crosses',
      },
      {
        id: 50,
        lexic_id: null,
        title: 'Crosses +',
      },
      {
        id: 52,
        lexic_id: null,
        title: 'Crosses -',
      },
    ],
    id: 3,
    title: 'Passes',
  },
  {
    actions: [
      {
        id: 54,
        lexic_id: null,
        title: 'Short Passes',
      },
      {
        id: 55,
        lexic_id: null,
        title: 'Short Passes +',
      },
      {
        id: 57,
        lexic_id: null,
        title: 'Short Passes -',
      },
      {
        id: 59,
        lexic_id: null,
        title: 'Middle Passes',
      },
      {
        id: 60,
        lexic_id: null,
        title: 'Middle Passes +',
      },
      {
        id: 62,
        lexic_id: null,
        title: 'Middle Passes -',
      },
      {
        id: 64,
        lexic_id: null,
        title: 'Long Passes',
      },
      {
        id: 65,
        lexic_id: null,
        title: 'Long Passes +',
      },
      {
        id: 67,
        lexic_id: null,
        title: 'Long Passes -',
      },
    ],
    id: 4,
    title: 'Passes by distance',
  },
  {
    actions: [
      {
        id: 69,
        lexic_id: null,
        title: 'Passes Forward',
      },
      {
        id: 70,
        lexic_id: null,
        title: 'Passes Forward +',
      },
      {
        id: 72,
        lexic_id: null,
        title: 'Passes Forward -',
      },
      {
        id: 74,
        lexic_id: null,
        title: 'Passes Backward',
      },
      {
        id: 75,
        lexic_id: null,
        title: 'Passes Backward +',
      },
      {
        id: 77,
        lexic_id: null,
        title: 'Passes Backward -',
      },
      {
        id: 79,
        lexic_id: null,
        title: 'Passes Sideways',
      },
      {
        id: 80,
        lexic_id: null,
        title: 'Passes Sideways +',
      },
      {
        id: 82,
        lexic_id: null,
        title: 'Passes Sideways -',
      },
    ],
    id: 5,
    title: 'Passes by side',
  },
  {
    actions: [
      {
        id: 44,
        lexic_id: null,
        title: 'Key Passes',
      },
      {
        id: 45,
        lexic_id: null,
        title: 'Key Passes +',
      },
      {
        id: 47,
        lexic_id: null,
        title: 'Key Passes -',
      },
      {
        id: 84,
        lexic_id: null,
        title: 'Progressive Passes',
      },
      {
        id: 85,
        lexic_id: null,
        title: 'Progressive Passes +',
      },
      {
        id: 87,
        lexic_id: null,
        title: 'Progressive Passes -',
      },
    ],
    id: 6,
    title: 'Advanced passes',
  },
  {
    actions: [
      {
        id: 100,
        lexic_id: null,
        title: 'Duels',
      },
      {
        id: 101,
        lexic_id: null,
        title: 'Duels +',
      },
      {
        id: 103,
        lexic_id: null,
        title: 'Duels -',
      },
      {
        id: 105,
        lexic_id: null,
        title: 'Aerial Duels',
      },
      {
        id: 106,
        lexic_id: null,
        title: 'Aerial Duels +',
      },
      {
        id: 108,
        lexic_id: null,
        title: 'Aerial Duels -',
      },
      {
        id: 110,
        lexic_id: null,
        title: 'Duels in Offence',
      },
      {
        id: 111,
        lexic_id: null,
        title: 'Duels in Offence +',
      },
      {
        id: 113,
        lexic_id: null,
        title: 'Duels in Offence -',
      },
      {
        id: 115,
        lexic_id: null,
        title: 'Duels in Defense',
      },
      {
        id: 116,
        lexic_id: null,
        title: 'Duels in Defense +',
      },
      {
        id: 118,
        lexic_id: null,
        title: 'Duels in Defense -',
      },
    ],
    id: 7,
    title: 'Duels',
  },
  {
    actions: [
      {
        id: 120,
        lexic_id: null,
        title: 'Tackles Made',
      },
      {
        id: 121,
        lexic_id: null,
        title: 'Tackles Made +',
      },
      {
        id: 123,
        lexic_id: null,
        title: 'Tackles Made -',
      },
      {
        id: 130,
        lexic_id: null,
        title: 'Interception',
      },
      {
        id: 131,
        lexic_id: null,
        title: 'Interception +',
      },
      {
        id: 133,
        lexic_id: null,
        title: 'Interception -',
      },
      {
        id: 142,
        lexic_id: null,
        title: 'Clearances',
      },
      {
        id: 135,
        lexic_id: null,
        title: 'Blocked shots',
      },
      {
        id: 95,
        lexic_id: null,
        title: 'Dribbles Faced',
      },
      {
        id: 96,
        lexic_id: null,
        title: 'Dribbles Faced +',
      },
      {
        id: 98,
        lexic_id: null,
        title: 'Dribbles Faced -',
      },
    ],
    id: 8,
    title: 'In defence',
  },
  {
    actions: [
      {
        id: 90,
        lexic_id: null,
        title: 'Dribbles Made',
      },
      {
        id: 91,
        lexic_id: null,
        title: 'Dribbles Made +',
      },
      {
        id: 93,
        lexic_id: null,
        title: 'Dribbles Made -',
      },
      {
        id: 89,
        lexic_id: null,
        title: 'Progressive Carries',
      },
      {
        id: 125,
        lexic_id: null,
        title: 'Tackles Faced',
      },
      {
        id: 126,
        lexic_id: null,
        title: 'Tackles Faced +',
      },
      {
        id: 128,
        lexic_id: null,
        title: 'Tackles Faced -',
      },
    ],
    id: 10,
    title: 'In offence',
  },
  {
    actions: [
      {
        id: 136,
        lexic_id: null,
        title: 'Ball Recoveries',
      },
      {
        id: 137,
        lexic_id: null,
        title: 'Ball Recoveries within 5 seconds',
      },
      {
        id: 138,
        lexic_id: null,
        title: 'Ball Recoveries within 10 seconds',
      },
      {
        id: 139,
        lexic_id: null,
        title: 'Ball Losses',
      },
      {
        id: 149,
        lexic_id: null,
        title: 'Ball Losses within 5 seconds',
      },
      {
        id: 141,
        lexic_id: null,
        title: 'Ball Losses within 10 seconds',
      },
    ],
    id: 11,
    title: 'Ball possession',
  },
  {
    actions: [
      {
        id: 146,
        lexic_id: null,
        title: 'Corners',
      },
      {
        id: 147,
        lexic_id: null,
        title: 'Free kicks',
      },
      {
        id: 148,
        lexic_id: null,
        title: 'Throw-ins',
      },
      {
        id: 149,
        lexic_id: null,
        title: 'Indirect Free Kicks',
      },
      {
        id: 150,
        lexic_id: null,
        title: 'Goal Kicks',
      },
    ],
    id: 12,
    title: 'Set pieces',
  },
  {
    actions: [
      {
        id: 160,
        lexic_id: null,
        title: 'Attacks , all',
      },
      {
        id: 161,
        lexic_id: null,
        title: 'Attack - left flank',
      },
      {
        id: 162,
        lexic_id: null,
        title: 'Attack with shots - left flank',
      },
      {
        id: 164,
        lexic_id: null,
        title: 'Attack - center flank',
      },
      {
        id: 165,
        lexic_id: null,
        title: 'Attack with shots - center flank',
      },
      {
        id: 167,
        lexic_id: null,
        title: 'Attack - right flank',
      },
      {
        id: 168,
        lexic_id: null,
        title: 'Attack with shots - right flank',
      },
      {
        id: 170,
        lexic_id: null,
        title: 'Positional attacks',
      },
      {
        id: 171,
        lexic_id: null,
        title: 'Positional attacks with shots',
      },
      {
        id: 173,
        lexic_id: null,
        title: 'Fast attacks',
      },
      {
        id: 174,
        lexic_id: null,
        title: 'Fast attacks with shots',
      },
      {
        id: 176,
        lexic_id: null,
        title: 'Set pieces attacks',
      },
      {
        id: 177,
        lexic_id: null,
        title: 'Attacks with shots - set pieces attacks',
      },
      {
        id: 179,
        lexic_id: null,
        title: 'Free kick attacks',
      },
      {
        id: 180,
        lexic_id: null,
        title: 'Free kick attacks with shots',
      },
      {
        id: 182,
        lexic_id: null,
        title: 'Corner attacks',
      },
      {
        id: 183,
        lexic_id: null,
        title: 'Corner attacks with shots',
      },
      {
        id: 185,
        lexic_id: null,
        title: 'Throw-in attacks',
      },
      {
        id: 186,
        lexic_id: null,
        title: 'Throw-in attacks with shots',
      },
      {
        id: 188,
        lexic_id: null,
        title: 'Goals - Free kick attack',
      },
      {
        id: 190,
        lexic_id: null,
        title: 'Penalties',
      },
      {
        id: 191,
        lexic_id: null,
        title: 'Penalties scored',
      },
      {
        id: 193,
        lexic_id: null,
        title: 'Wing Attacks',
      },
      {
        id: 194,
        lexic_id: null,
        title: 'Wing Attacks with shots',
      },
      {
        id: 196,
        lexic_id: null,
        title: 'Counter-attacks',
      },
      {
        id: 197,
        lexic_id: null,
        title: 'Counter-attacks with a shot',
      },
      {
        id: 199,
        lexic_id: null,
        title: 'Counterpress attack',
      },
      {
        id: 200,
        lexic_id: null,
        title: 'Counterpress attack with shots',
      },
      {
        id: 202,
        lexic_id: null,
        title: 'Long ball attacks',
      },
      {
        id: 203,
        lexic_id: null,
        title: 'Long ball attacks with shots',
      },
    ],
    id: 13,
    title: 'Attack types',
  },
];

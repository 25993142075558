export const RecordIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='35'
    height='32'
    viewBox='0 0 35 32'
    fill='none'
  >
    <circle cx='17.5' cy='8.5' r='8' fill='white' stroke='white' />
    <path
      d='M10.7537 26.808H9.49366V30H8.52166V21.912H10.5497C11.4857 21.912 12.1937 22.132 12.6737 22.572C13.1617 23.012 13.4057 23.62 13.4057 24.396C13.3897 24.956 13.2417 25.432 12.9617 25.824C12.6817 26.216 12.2857 26.492 11.7737 26.652L13.8977 30H12.7337L10.7537 26.808ZM10.6097 22.752H9.49366V25.98H10.7177C11.2697 25.98 11.6897 25.832 11.9777 25.536C12.2737 25.232 12.4217 24.844 12.4217 24.372C12.4217 23.292 11.8177 22.752 10.6097 22.752ZM19.6079 30H15.0959V21.912H19.5479V22.764H16.0679V25.536H19.1399V26.388H16.0679V29.148H19.6079V30ZM24.8636 30.096C23.6476 30.096 22.6596 29.724 21.8996 28.98C21.1396 28.228 20.7596 27.228 20.7596 25.98C20.7596 24.724 21.1436 23.716 21.9116 22.956C22.6876 22.188 23.6596 21.804 24.8276 21.804C25.6676 21.804 26.3516 21.948 26.8796 22.236V23.232C26.5276 23.048 26.2116 22.916 25.9316 22.836C25.6516 22.756 25.2836 22.716 24.8276 22.716C23.9556 22.716 23.2276 23.012 22.6436 23.604C22.0676 24.196 21.7796 24.98 21.7796 25.956C21.7796 26.924 22.0676 27.704 22.6436 28.296C23.2196 28.888 23.9836 29.184 24.9356 29.184C25.7036 29.184 26.3596 28.98 26.9036 28.572V29.592C26.4076 29.928 25.7276 30.096 24.8636 30.096Z'
      fill='white'
    />
  </svg>
);

export const PlayCircleIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='23'
    height='24'
    viewBox='0 0 23 24'
    fill='none'
  >
    <path
      d='M21.6896 11.202C22.3929 11.579 22.3929 12.5876 21.6896 12.9646L2.05605 23.4904C1.38987 23.8475 0.583556 23.3649 0.583556 22.609L0.583557 1.55756C0.583557 0.801684 1.38988 0.319079 2.05605 0.676224L21.6896 11.202Z'
      fill='white'
    />
  </svg>
);

import { MouseEvent } from 'react';
import {
  UiDropdown,
} from '../../../ui';
import cls from '../SelectEventsCard/SelectEventsCard.module.css';
import { Modal } from '../../../Modal/index';

type Option = {
  checked?: boolean,
  label: string,
  value: string | number,
}
type Props = {
  className?: string,
  competitions: Array<Option>,
  handleChangeSeason: (opt: Option) => void,
  handleResetSettings: () => void,
  handleSaveSettings: () => void,
  handleSelectedCompetition: () => void,
  seasons: Array<Option>,
  selectedCountMatches: Array<Option>,
  setIsOpenSettings: (bool: boolean) => void,
  setSelectedCountMatches: () => void,
  withClose?: boolean,
}
export const MatchesSettings = ({
  className,
  competitions,
  handleChangeSeason,
  handleResetSettings,
  handleSaveSettings,
  handleSelectedCompetition,
  seasons,
  selectedCountMatches,
  setIsOpenSettings,
  setSelectedCountMatches,
  withClose = false,
}: Props) => (
  <Modal
    withClose={withClose}
    // @ts-ignore
    onClose={(e: MouseEvent<HTMLDivElement>) => {
      e?.preventDefault()
      handleSaveSettings()
      setIsOpenSettings(false)
    }}
    className={className}
  >
    <form
      className={cls.settingsform}
      onSubmit={(e) => {
        e.preventDefault()
        handleSaveSettings()
        setIsOpenSettings(false)
      }}
    >
      <div className={cls.settingsform__item}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          Season
        </label>
        <UiDropdown
          onSelected={(val) => handleChangeSeason(val)}
          options={seasons}
          // @ts-ignore
          title={seasons?.find(({ checked }) => checked)?.label ?? 'none'}
        />
      </div>
      {/* TODO: убираем до лучших времен, потому что сезоны сейчас не привязаны  к датам */}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      {/* <div className={cls.settingsform__item}> */}
      {/*  /!* eslint-disable-next-line jsx-a11y/label-has-associated-control *!/ */}
      {/*  <label>DATE:</label> */}
      {/*  <DatePicker */}
      {/*    wrapperClassName={cls.datepicker} */}
      {/*    selected={startDate} */}
      {/*    onChange={(date) => setStartDate(new Date(date))} */}
      {/*    selectsStart */}
      {/*    startDate={startDate} */}
      {/*    endDate={endDate} */}
      {/*    dateFormat='yyyy-MM-dd' */}
      {/*  /> */}
      {/*  &nbsp; - &nbsp; */}
      {/*  <DatePicker */}
      {/*    wrapperClassName={cls.datepicker} */}
      {/*    selected={endDate} */}
      {/*    onChange={(date) => setEndDate(new Date(date))} */}
      {/*    selectsEnd */}
      {/*    minDate={startDate} */}
      {/*    startDate={startDate} */}
      {/*    endDate={endDate} */}
      {/*    dateFormat='yyyy-MM-dd' */}
      {/*  /> */}
      {/* </div> */}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <div className={cls.settingsform__item}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          competition
        </label>
        <UiDropdown
          onSelected={handleSelectedCompetition}
          multiChoose
          options={competitions}
          // @ts-ignore
          title={competitions?.find(({ checked }) => checked)?.label ?? 'none'}
        />
      </div>
      <div className={cls.settingsform__item}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          pre-set season matches
        </label>
        <UiDropdown
          onSelected={(selected) => {
            setSelectedCountMatches(
              // @ts-ignore
              (prev) => prev.map(
                // @ts-ignore
                (count) => (count.value === selected.value
                  ? { ...count, checked: !count?.checked }
                  : { ...count, checked: false }),
              ),
            )
          }}
          options={selectedCountMatches}
          title={selectedCountMatches?.find(({ checked }) => checked)?.label ?? 'none'}
        />
      </div>
      <div className={cls.settingsform_footer}>
        <button
          className={cls.settingsform__item}
          type='submit'
        >Accept
        </button>
        <button
          onClick={handleResetSettings}
          className={cls.settingsform__item}
          type='button'
        >reset
        </button>
      </div>
    </form>
  </Modal>
)

import React from 'react';
import { useLexicon } from 'lib/hooks/useTranslates';
import cls from './VideoCutsPanel.module.css';

export const VideoCutsFooterInfo = (props) => {
  const {
    countEpisode,
    countMatches,
    openAddPlayList,
  } = props;
  const l = useLexicon();
  return (
    <footer
      className={cls.videoCutsFooterInfoContainer}
      style={openAddPlayList ? { opacity: '0.1' } : {}}
    >
      <div className={cls.videoCutsFooterPlayCircleWrapper}>
        <div className={cls.videoCutsFooterPlayCircle}>
          <svg
            width={8}
            height={8}
            xmlns='http://www.w3.org/2000/svg'
            fill='#B4EB54'
            viewBox='0 0 24 24'
            strokeWidth={1}
            stroke='#B4EB54'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z'
            />
          </svg>
        </div>
        <span>{countEpisode} {l(9)}</span>
      </div>
      <div>{countMatches} {l(7)}</div>
      <div>
        ~{new Date(countEpisode * 10 * 1000).toISOString().slice(11, 19)}
      </div>
    </footer>
  );
};

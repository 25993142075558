export const DownloadIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='100%'
    height='100%'
    viewBox='0 0 46 55'
    fill='none'
  >
    <path
      d='M10.6162 39.82C10.6162 39.334 10.9982 38.94 11.4693 38.94H33.6497C34.1209 38.94 34.5028 39.334 34.5028 39.82C34.5028 40.306 34.1209 40.7 33.6497 40.7H11.4693C10.9982 40.7 10.6162 40.306 10.6162 39.82Z'
      fill='#0f1521'
    />
    <path
      d='M19.1471 27.06V16.94C19.1471 16.697 19.3381 16.5 19.5737 16.5H25.5454C25.7809 16.5 25.9719 16.697 25.9719 16.94V27.06C25.9719 27.303 26.1629 27.5 26.3984 27.5H29.6704C30.0451 27.5 30.2377 27.9628 29.9796 28.2431L22.8688 35.9642C22.7006 36.1468 22.4184 36.1468 22.2503 35.9642L15.1394 28.2431C14.8813 27.9628 15.0739 27.5 15.4487 27.5H18.7206C18.9562 27.5 19.1471 27.303 19.1471 27.06Z'
      fill='#0f1521'
    />
  </svg>
);
